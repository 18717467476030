import { Chip } from '@mui/material';
import Stack from '@mui/material/Stack';
import React from 'react';
import PropTypes from 'prop-types';

import deleteIcon from '../../../assets/icons/Close.png';
import Button from '../Button';

function MultiSelectBox(props) {
  const handleDelete = (e) => {
    props?.addData(props?.name, e);
  };

  return (
    <div className="input-black">
      <label className="label" htmlFor={props?.name}>
        {props?.label} {props?.required && <span className="text-danger">*</span>}
      </label>
      <div name={props?.name} role="presentation" className="select-input-box">
        <div className="list-chips">
          <Stack direction="row" style={{ flexWrap: 'wrap', gap: 10 }}>
            {
              props?.value.map((e) => (
                <Chip
                  label={e.name}
                  onDelete={props?.layout === e.type ? undefined : () => handleDelete(e)}
                  deleteIcon={<img src={deleteIcon} alt="delete_image_icon" style={{ width: 16, height: 16 }} />}
                />
              ))
            }
            {props?.value.length === 0
              && <p style={{ color: '#757575', fontSize: '14px', margin: 0 }}>{props?.placeholder}</p>}
          </Stack>
        </div>
        <Button
          classes="default-button"
          click={() => props?.clickButton()}
          label={props?.buttonLabel}
          id={props?.name}
          disabled={props?.disabled}
        />
      </div>
    </div>
  );
}

MultiSelectBox.propTypes = {
  addData: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(),
  buttonLabel: PropTypes.string.isRequired,
  clickButton: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  layout: PropTypes.string,
};
MultiSelectBox.defaultProps = {
  required: false,
  value: [],
  placeholder: '',
  disabled: false,
  layout: '',
};

export default MultiSelectBox;

import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import TopContent from '../../shared/component/TopContent';
import AvailableLayouts from './components/AvailableLayouts';
import SavedLayouts from './components/SavedLayouts';
// import Button from '../../shared/component/Button';
import LayoutPopup from './components/LayoutPopup';

function ContentLayout() {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const [newLayoutClick, setNewLayoutClick] = useState(false);
  const contentLayoutTabs = [
    {
      id: 1,
      label: t('layouts'),
      key: 'layouts',
    },
    {
      id: 2,
      label: t('saved'),
      key: 'saved',
    },
  ];

  const handleSwitchTab = (key) => {
    navigate(`../${key}`);
  };

  // const submit = () => {
  //   setNewLayoutClick(true);
  // };

  const handleClosePopup = () => {
    setNewLayoutClick(false); // Close the popup
  };
  return (
    <div className="content-layout">
      <div className="layout-header">
        <TopContent
          label={t('contentLayout')}
        />
        {/* <Button
          label={t('newLayout')}
          click={submit}
          classes="success-button"
        /> */}
      </div>
      <div className="content-layout-wrap">
        <div className="content-layout-tabs">
          {contentLayoutTabs?.map((tab) => (
            <div
              role="presentation"
              onClick={() => handleSwitchTab(tab?.key)}
              className="each-tab"
            >
              <h1
                style={{ '--active-tab-color': tab?.key === params?.tab ? '#03A84B' : '#717880' }}
              >
                {tab?.label}
              </h1>
              <div
                className="active-tab-border"
                style={{ '--active-tab-width': tab?.key === params?.tab ? '95%' : '0%' }}
              />
            </div>
          ))}
        </div>
        <div className="content-layout-container">
          {params?.tab === 'layouts' && <AvailableLayouts />}
          {params?.tab === 'saved' && <SavedLayouts />}
        </div>
      </div>
      {newLayoutClick && <LayoutPopup open={newLayoutClick} onClose={handleClosePopup} />}
    </div>
  );
}

export default ContentLayout;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { Input } from '../../../shared/component/form/Input';
import Button from '../../../shared/component/Button';
import SelectBox from '../../../shared/component/form/SelectBox';

import devices from '../../../utils/api/devices';

import { fetchStoreLocationListAll } from '../../../redux/slices/StoreLocationSlice';
import { updateDisplay } from '../../../redux/slices/DisplaySignageSlice';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import { nameValidation } from '../../../shared/component/form/Validation';

function DisplaySignageEditNew(props) {
  const { close, submitDisplay, selectValue } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const storeData = useSelector((state) => state.store.storeLocationAll);

  const [value, setValue] = useState({
    ...selectValue,
    store_name: selectValue?.store_name,
    store: selectValue?.store?.id,
  });
  const [stores, setStores] = useState([]);
  const [disable, setDisable] = useState(true);

  // For Notification
  const [uploading, setUploading] = useState(false);

  const submit = () => {
    if (nameValidation(1, 50, value?.name)) {
      setDisable(true);
      setUploading(true);
      const data = {
        name: value.name,
        store: value.store,
        id: value.id,
      };
      devices.updateDevice(data).then((res) => {
        dispatch(updateDisplay(res?.data));
        submitDisplay(t('displaySignageUpdateSuccess'));
      }).catch((error) => {
        setUploading(false);
        setDisable(false);
        dispatch(setErrorNotification(error?.response?.data));
      });
    }
  };

  const changeHandle = (name, changeValue) => {
    if (name === 'store') {
      if (changeValue) {
        setValue({ ...value, [name]: changeValue.id, store_name: changeValue.label });
      }
    } else {
      setValue({ ...value, [name]: changeValue });
    }
  };

  useEffect(() => {
    if (storeData.length === 0) {
      dispatch(fetchStoreLocationListAll());
    }
    if (stores.length === 0) {
      const store = storeData.map((val) => ({ label: val.name, id: val.id }));
      setStores(store);
    }
    if (value.store && value.name && value.device_system_id) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [storeData, value]);

  return (
    <div className="signage-form-wrap">
      <div className="signage-form">
        <div className="input-text-area">
          <SelectBox
            label={t('storeName')}
            name="store"
            required
            value={value.store_name}
            onchange={changeHandle}
            lists={stores}
          />
        </div>
        <div className="input-text-area">
          <Input
            icon={' '}
            label={t('deviceName')}
            type="text"
            name="name"
            required
            value={value.name}
            change={changeHandle}
            error={!nameValidation(1, 50, value?.name)}
            errorMessage={t('deviceNameValidation')}
          />
        </div>
      </div>
      <div className="form-button-group">
        <div className="form-button">
          <Button label={t('back')} click={close} classes="default-button" />
        </div>
        <div className="form-button">
          <Button
            label={uploading ? t('updating') : t('update')}
            click={submit}
            classes={uploading ? 'success-button loading-btn' : 'success-button'}
            disabled={disable}
            loading={uploading}
          />
        </div>
      </div>
    </div>
  );
}
DisplaySignageEditNew.propTypes = {
  close: PropTypes.func.isRequired,
  submitDisplay: PropTypes.func.isRequired,
  selectValue: PropTypes.objectOf.isRequired,
};
// DisplaySignageAddNew.defaultProps = {
//   toolbarLeft: '',
// };

export default DisplaySignageEditNew;

/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from '@mui/material';
import TopContent from '../../../../shared/component/TopContent';
import { Input } from '../../../../shared/component/form/Input';
import Button from '../../../../shared/component/Button';
import { nameValidation, integerValidation } from '../../../../shared/component/form/Validation';
import { createWidgetStoreLocally } from '../../../../redux/slices/WidgetTempSlice';
import { setErrorNotification } from '../../../../redux/slices/NotificationSlice';
import { getRssTemplate } from '../shared';

function RSSFeedAddForm() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();

  const { widgetStoreData } = useSelector((stateValue) => stateValue.widgets);
  const selectedWidget = useSelector((stateValue) => stateValue.widgets.selectedWidget);

  const [formValue, setFormValue] = useState(widgetStoreData || { duration: '05' });
  const [disable, setDisable] = useState(true);
  const [displayTypeUnit, setDisplayTypeUnit] = useState(widgetStoreData?.displayUnit);
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
  const [invalidUrl, setInvalidUrl] = useState(false);

  const handleChange = (name, changeValue) => {
    setFormValue({ ...formValue, [name]: changeValue });
  };

  const handleDisplayTypeChange = (e) => {
    const selectedTemplate = selectedWidget.widget_templates.find(
      (template) => template.name === e.target.value,
    );
    setDisplayTypeUnit(e.target.value);
    const form = JSON.parse(JSON.stringify(formValue));
    form.displayUnit = e.target.value;
    form.html_url = selectedTemplate.html_url;
    form.template_id = selectedTemplate._id;
    form.script_url = selectedWidget?.script_url;
    setFormValue(form);
  };

  const navigateBack = () => {
    navigate('/admin/apps');
  };

  const handlePreview = async () => {
    setSubmitButtonClicked(true);
    if (nameValidation(1, 100, formValue?.name) && integerValidation(1, 1000, formValue.duration)) {
      try {
        const rssData = await getRssTemplate(formValue.feedUrl);
        const hasValidImageUrls = rssData.every((item) => item.imageUrl && item.imageUrl.trim() !== '');
        if (rssData.length > 0 && hasValidImageUrls) {
          dispatch(createWidgetStoreLocally(formValue));
          navigate('/admin/apps/RssFeed-image-page', {
            state:
            {
              ...formValue,
              displayTypeUnit,
              id: formValue.template_id,
            },
          });
        } else {
          setInvalidUrl(true);
        }
      } catch (error) {
        dispatch(setErrorNotification(error.message));
      }
    }
  };
  useEffect(() => {
    if (!selectedWidget || selectedWidget?.widget_templates === 0) {
      navigate('/admin/apps/widgets');
    }
  }, []);
  useEffect(() => {
    if (
      formValue.name
      && formValue.duration
      && formValue.feedUrl
      && displayTypeUnit
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [formValue, displayTypeUnit]);

  return (
    <div className="content content-wrap">
      <div className="main-content">
        <TopContent label={[t('apps'), t('rssFeed'), state?.name]} button={false} />
        <div className="content-area">
          <div className="my-content">
            <div className="my-content-area-form">
              <div className="user-form">
                <p style={{ fontWeight: '600' }}>{t('rssFeed')}</p>
                <div className="single-row">
                  <Input
                    icon={' '}
                    label={t('widgetName')}
                    type="text"
                    name="name"
                    required
                    value={formValue?.name}
                    change={handleChange}
                    placeholder={t('enterWidgetName')}
                    error={
                      !nameValidation(1, 100, formValue?.name) && submitButtonClicked
                    }
                    errorMessage={t('nameValidation')}
                  />
                  <Input
                    icon={' '}
                    label={t('rssFeedUrl')}
                    type="text"
                    name="feedUrl"
                    required
                    value={formValue?.feedUrl}
                    change={handleChange}
                    placeholder={t('rssFeedUrl')}
                    error={invalidUrl}
                    errorMessage={t('urlValidation')}
                  />
                </div>
                <div className="single-row">
                  <Input
                    icon={' '}
                    label={t('durationMinutes')}
                    type="number"
                    name="duration"
                    required
                    value={formValue?.duration}
                    change={handleChange}
                    minLength="1"
                    placeholder={t('enterDuration')}
                    error={
                      !integerValidation(1, 1000, formValue.duration) && submitButtonClicked
                    }
                    errorMessage={t('durationValidation')}
                  />
                  <div className="temperature">
                    <FormControl>
                      <FormLabel
                        style={{ color: '#212B36' }}
                        id="demo-radio-buttons-group-label"
                      >
                        {t('displayType')}
                        <span className="text-danger">*</span>
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={formValue?.displayUnit}
                        onChange={handleDisplayTypeChange}
                      >
                        {selectedWidget?.widget_templates?.map((template) => (
                          <FormControlLabel
                            key={template._id}
                            value={template.name}
                            control={(
                              <Radio
                                sx={{
                                  color: '#30A84B',
                                  '&.Mui-checked': {
                                    color: '#30A84B',
                                  },
                                }}
                              />
                            )}
                            label={template.name}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>

                <div className="form-button-group">
                  <div className="form-button">
                    <Button label={t('back')} click={navigateBack} classes="default-button" />
                  </div>
                  <div className="form-button">
                    <Button
                      disabled={disable}
                      label={t('preview')}
                      click={handlePreview}
                      classes="success-button"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RSSFeedAddForm;

/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { AdvanceEditIcon } from '../../../shared/component/svgIcon';
import TopContent from '../../../shared/component/TopContent';
import {
  Input, InputDate, InputTime,
} from '../../../shared/component/form/Input';
import Button from '../../../shared/component/Button';
import MultiSelectBox from '../../../shared/component/form/MultiSelectBox';
import ModalBox from '../../../shared/component/ModalBox';
import ContentsList from './ContentsList';
import SelectBox from '../../../shared/component/form/SelectBox';
import DeviceList from './DeviceList';
import AdvanceSettings from './AdvanceSetting';
import RepeatForm from './RepeatForm';

import timeZonesList from '../../../assets/json/time-zone.json';

import PreviewContent from './PreviewContent';

import scheduleApi from '../../../utils/api/schedule';

import {
  fetchCalendarScheduleList,
  createNewSchedule,
  addNewSchedule,
} from '../../../redux/slices/ScheduleSlice';
import Success from '../../../shared/component/Success';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import { checkFeatures, checkPermission } from '../../../utils/helpers';
import ContentOptions from './ContentOptions';
import SavedLayouts from './layoutSchedule/SavedLayouts';
import { resetScheduleDisplaySignageState } from '../../../redux/slices/ScheduleDisplaySignageSlice';
import { resetScheduleDisplayGroupState } from '../../../redux/slices/ScheduleDisplayGroupSlice';
import { descriptionValidation } from '../../../shared/component/form/Validation';

function ScheduleAdd() {
  const { t } = useTranslation();
  let backClick;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const devicesData = useSelector((state) => state.display.displaySignage);
  const {
    week,
    newSchedule,
  } = useSelector((state) => state.schedules);
  const devicesGroupData = useSelector((state) => state.displayGroup.displayGroup);
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
  const [open, setOpen] = useState(false);
  const [disable, setDisable] = useState(true);
  const [modalTitle, setModalTitle] = useState(t('contents'));
  const [modalView, setModalView] = useState();
  const [selectContents, setSelectContents] = useState([]);
  const [selectDevices, setSelectDevices] = useState([]);
  const [timeZone, setTimeZone] = useState([]);
  const [repeatDisable, setRepeatDisable] = useState(true);
  const [repeatText, setRepeatText] = useState(t('doesNotRepeat'));
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [value, setValue] = useState(newSchedule?.schedule || {
    playlists: [],
    playlistDetails: [],
    contents: [],
    contentsDetails: [],
    devices: [],
    device_groups: [],
    repeat_days: [],
    advanceSettings: [],
    repeat: false,
    special: false,
  });
  const [dateTime, setDateTime] = useState(newSchedule?.muiDateTime || {});
  // For Notification
  const [uploading, setUploading] = useState(false);
  const [notification, setNotification] = useState(false);
  // const [contentPreference, setContentPreference] = useState(false);
  const sortedArray = week.slice().sort((a, b) => new Date(a?.start_date) - new Date(b?.start_date));
  const addSchedule = () => {
    localStorage.removeItem('value');
    navigate('../');
  };

  const submit = () => {
    // Split the date and time strings and create Date objects
    setSubmitButtonClicked(true);
    const startDuration = new Date(`${value.start_date}T${value.start_time}`);
    const endDuration = new Date(`${value.end_date}T${value.end_time}`);

    // Check if start date and time is greater than or equal to end date and time
    if (startDuration.getTime() < endDuration.getTime()) {
      setDisable(true);
      setUploading(true);
      const request = {
        name: value.name,
        repeat: value.repeat,
        start_only_date: value.start_date,
        end_only_date: value.end_date,
        start_time: value.start_time,
        end_time: value.end_time,
        time_zone: value.time_zone,
        special: value.special,
        // ai_content_recommendation: contentPreference,
      };

      if (value.description !== '') {
        request.description = value.description;
      }

      if (value.contents.length > 0) {
        request.contents = value.contents;
      }

      if (value.devices.length > 0) {
        request.devices = value.devices;
      }

      if (value.device_groups.length > 0) {
        request.device_groups = value.device_groups;
      }

      if (value.playlists.length > 0) {
        request.playlists = value.playlists;
      }

      if (value.repeat) {
        request.recurrences = {
          interval: value.repeat_count,
          frequency: value.repeat_type,
          start_only_date: value.started_date,
          end_only_date: value.complete_date,
        };

        if (value.repeat_type === 'WEEKLY') {
          request.recurrences.by_week_day = value.repeat_days;
        }
      }

      if (value.layouts) {
        request.layout = {
          myContentLayout_id: value?.layouts?._id,
          device_layouts: value?.layouts?.device_layouts,
          content_layouts: value?.layouts?.layouts?.map((eachLayout) => {
            let finalLayout;
            if (!(eachLayout?.file_types?.[0] === 'weather'
              || eachLayout?.file_types?.[0] === 'clock'
              || eachLayout?.file_types?.[0] === 'rss')) {
              finalLayout = {
                _id: eachLayout?._id,
                name: eachLayout?.name,
                grid_column_start: eachLayout?.grid_column_start,
                grid_column_end: eachLayout?.grid_column_end,
                grid_row_start: eachLayout?.grid_row_start,
                grid_row_end: eachLayout?.grid_row_end,
                partition_id: eachLayout?.partition_id,
                file_types: eachLayout?.file_types,
                contents: eachLayout?.contents
                  ?.filter((content) => content?.file_type !== 'playlist')
                  ?.map((content) => content?._id),
                playlists: eachLayout?.contents
                  ?.filter((content) => content?.file_type === 'playlist')
                  ?.map((content) => content?._id),
              };
            } else {
              finalLayout = {
                _id: eachLayout?._id,
                name: eachLayout?.name,
                grid_column_start: eachLayout?.grid_column_start,
                grid_column_end: eachLayout?.grid_column_end,
                grid_row_start: eachLayout?.grid_row_start,
                grid_row_end: eachLayout?.grid_row_end,
                partition_id: eachLayout?.partition_id,
                file_types: eachLayout?.file_types,
                contents: eachLayout?.contents,
              };
            }
            return finalLayout;
          }),
        };
      }
      if (newSchedule?.schedule?.advanceSettings) {
        request.advance_settings = newSchedule?.schedule?.advanceSettings.map((setting) => ({
          content_id: setting.content_id,
          playlist: setting.playlist,
          device_id: setting.device_id,
          device_group: setting.device_group,
          partition_id: setting.partition_id,
          partition_name: setting.partition_name,
          content_action: setting.content_action,
          content_fit: setting.content_fit,
          content_size_in_pixels: setting.content_size_in_pixels,
          required_size_in_pixels: setting.required_size_in_pixels,
          content_orientation: setting.content_orientation,
          duration: setting.duration,
          volume: setting.volume,
          volume_play_range_type: setting.volume_play_range_type,
          volume_play_range: setting.volume_play_range,
          start_transition: setting.start_transition,
          end_transition: setting.end_transition,
        }));
      }
      if (descriptionValidation(100, value?.description)) {
        setUploading(true);
        scheduleApi.addSchedule(request)
          .then((res) => {
            dispatch(createNewSchedule(res?.data));
            setUploading(false);
            setNotification(true);
            setTimeout(() => {
              setNotification(false);
              setDisable(false);
              navigate('../');
            }, 3000);

            // Assuming sortedArray is defined somewhere in your component
            if (sortedArray[0]?.start_date) {
              dispatch(fetchCalendarScheduleList({
                start_date: sortedArray[0]?.start_date,
                end_date: sortedArray[sortedArray.length - 1]?.end_date,
                status: 'add',
              }));
            }
          })
          .catch((errors) => {
            setUploading(false);
            setError(errors?.response?.data?.message);
            setDisable(false);
            dispatch(setErrorNotification(errors?.response?.data));
          });
      } else {
        setUploading(false);
        setDisable(false);
      }
    } else {
      dispatch(setErrorNotification({ message: 'Enter a valid schedule time' }));
    }
  };

  const setSpecialSchedule = () => {
    setValue({
      ...value,
      special: !value.special,
    });
  };

  const selectData = (name, data) => {
    if (name === 'time_zone') {
      if (data) {
        setValue((prevState) => ({
          ...prevState,
          time_zone: data.id,
        }));
      }
    } else if (name === 'devices' || name === 'contents') {
      setValue((prevState) => ({
        ...prevState,
        ...data,
      }));
    } else if (name === 'start_date' || name === 'end_date') {
      setDateTime((prevState) => ({
        ...prevState,
        [name]: data,
      }));
      setTimeout(() => {
        setValue((prevState) => ({
          ...prevState,
          [name]: dayjs(data).format('YYYY-MM-DD'),
        }));
      }, 1000);
    } else if (name === 'start_time' || name === 'end_time') {
      setDateTime((prevState) => ({
        ...prevState,
        [name]: data,
      }));
      // Formatting time
      const hour = data?.$H;
      const minute = data?.$m;
      const formattedTime = `${hour < 10 ? '0' : ''}${hour}:${minute < 10 ? '0' : ''}${minute}`;
      setTimeout(() => {
        setValue((prevState) => ({
          ...prevState,
          [name]: formattedTime,
        }));
      }, 1000);
    } else {
      setValue((prevState) => ({
        ...prevState,
        [name]: data,
      }));
    }
  };
  const backModal = (name, data) => {
    backClick(name, data);
  };

  const advanceSetting = async (name, data) => {
    selectData(name, data);

    setModalView(<AdvanceSettings
      type="scheduleAdd"
      value={value}
      setValue={setValue}
      name={name}
      data={data}
      closeModal={setOpen}
      selectData={selectData}
      values={value}
      back={backModal}
    />);
    setModalTitle(t('advanceSetting'));
  };

  const viewData = (name, data) => {
    const selectedData = (name === 'devices' || (name === 'contents' && selectDevices?.length !== 0))
      ? advanceSetting
      : selectData;
    setModalView(<PreviewContent
      data={data}
      closeModal={setOpen}
      name={name}
      selectData={selectedData}
      values={value}
      back={backModal}
    />);
  };

  const setRepeated = (repValues) => {
    setValue({ ...value, ...repValues, repeat: true });
    setMessage(
      repValues.repeat_type === 'DAILY'
        ? `Occurs every ${repValues.repeat_count > 1 ? repValues.repeat_count : ''} 
    day starting ${repValues.started_date} until ${repValues.complete_date}`
        : `Occurs every ${repValues.repeat_count > 1 ? repValues.repeat_count : ''} 
    week ${repValues?.repeat_days} starting ${repValues.started_date} until ${repValues.complete_date}`,
    );
    setOpen(false);
  };

  const openModalRepeat = () => {
    setModalView(<RepeatForm
      closeModal={setOpen}
      setRepeated={setRepeated}
      startDate={value}
    />);
    setModalTitle(t('repeat'));
    setOpen(true);
  };

  const removeContentsTags = (name, key) => {
    if (key.type === 'contents' || key.type === 'playlists') {
      const contentsList = value[key.type].filter((e) => e !== key.id);
      let detail = '';
      let oldValue = [];
      let updatedAdvanceSettings;
      if (key.type === 'contents') {
        detail = 'contentsDetails';
        oldValue = value?.contentsDetails.filter((content) => content.id !== key.id);
        if (value.advanceSettings) {
          updatedAdvanceSettings = value?.advanceSettings
            .filter((schedule) => schedule.content_id !== key.id);
        }
      } else if (key.type === 'playlists') {
        detail = 'playlistDetails';
        oldValue = value?.playlistDetails.filter((content) => content.id !== key.id);
        if (value?.advanceSettings) {
          updatedAdvanceSettings = value?.advanceSettings
            .filter((schedule) => schedule.playlist !== key.id);
        }
      }
      setValue(
        {
          ...value, [key.type]: contentsList, [detail]: oldValue, advanceSettings: updatedAdvanceSettings || [],
        },
      );
    } else if ('layouts' in value && key.type === 'layout') {
      const { layouts: _, entireLayout, ...newValue } = value;
      setValue({ ...newValue, advanceSettings: [] });
      setSelectContents((prevValue) => [
        ...prevValue.filter((content) => content.type === 'layout'),
      ]);
    } else {
      const deviceList = value[key.type].filter((e) => e !== key.id);
      let detail = '';
      let oldValue = [];
      let updatedAdvanceSettings;
      if (key.type === 'devices') {
        detail = 'devices_list';
        oldValue = value.devices_list.filter((content) => content.id !== key.id);
        updatedAdvanceSettings = value?.advanceSettings
          .filter((setting) => setting.device_id !== key.id);
      } else if (key.type === 'device_groups') {
        detail = 'device_groups_list';
        oldValue = value.device_groups_list.filter((content) => content.id !== key.id);
        updatedAdvanceSettings = value?.advanceSettings
          .filter((setting) => setting.device_group !== key.id);
      }
      setValue(
        {
          ...value, [key.type]: deviceList, [detail]: oldValue, advanceSettings: updatedAdvanceSettings || [],
        },
      );
    }
  };

  const openModalContent = (name = 'contents', data = {}) => {
    const oldData = Object.keys(data).length !== 0 ? data : value;
    setModalView(<ContentsList selectData={viewData} closeModal={setOpen} values={oldData} name={name} />);
    setModalTitle(t('contents'));
    setOpen(true);
  };

  const openSavedLayouts = () => {
    setModalTitle(t('savedLayouts'));
    setModalView(<SavedLayouts closeModal={setOpen} type="scheduleAdd" />);
    setOpen(true);
  };

  const openContentOptions = () => {
    if (selectContents?.length === 0) {
      setModalTitle(t('confirmation'));
      setModalView(<ContentOptions
        openSavedLayouts={openSavedLayouts}
        openModalContent={openModalContent}
        closeModal={setOpen}
      />);
      setOpen(true);
    } else {
      openModalContent();
    }
  };

  const openModalDevice = (name = 'devices', data = {}) => {
    const oldData = Object.keys(data).length !== 0 ? data : value;
    setModalView(<DeviceList
      selectData={viewData}
      closeModal={setOpen}
      values={oldData}
      name={name}
      layout={value?.layouts}
      contents={value?.contentsDetails}
      playlists={value?.playlistDetails}
    />);
    setModalTitle(t('devices'));
    setOpen(true);
  };

  backClick = (name, data) => {
    if (name === 'contents' || name === 'playlists') {
      openModalContent(name, data);
    } else if (name === 'devices' || name === 'device_groups') {
      openModalDevice(name, data);
    }
  };

  const closeModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    const timeZones = timeZonesList.map((val) => ({ id: val, label: val }));
    setTimeZone(timeZones);
  }, [navigate]);

  useEffect(() => {
    if (value && dateTime) {
      dispatch(addNewSchedule({
        schedule: value,
        muiDateTime: dateTime,
      }));
    }
    if (value.start_date && value.start_time && value.end_date && value.end_time && value.name && value.time_zone) {
      if (
        (value.contents?.length > 0
          || value.playlists?.length > 0
          || Object.keys(value)?.includes('layouts'))
        && (value.devices?.length > 0 || value.device_groups?.length > 0)
      ) {
        setDisable(false);
      } else {
        setDisable(true);
      }
    } else {
      setDisable(true);
    }
    let singleDevice = [];
    let singleDevicesGroup = [];
    const device = devicesData.filter((e) => value.devices.includes(e.id));
    singleDevice = device.map((value1) => ({ ...value1, type: 'devices' }));
    const deviceGroup = devicesGroupData.filter((e) => value.device_groups.includes(e.id));
    singleDevicesGroup = deviceGroup.map((value1) => ({ ...value1, type: 'device_groups' }));
    setSelectDevices([...singleDevice, ...singleDevicesGroup]);
    if (Object.keys(value)?.includes('layouts')) {
      setSelectContents([
        {
          id: value?.layouts?.id,
          name: value?.layouts?.name,
          type: 'layout',
        },
      ]);
    } else {
      setSelectContents(
        [
          ...value.contentsDetails.map((value1) => ({ ...value1, type: 'contents' })),
          ...value.playlistDetails.map((value1) => ({ ...value1, type: 'playlists' })),
        ],
      );
    }
  }, [value]);

  useEffect(() => {
    if (value.start_date && value.start_time && value.end_date && value.end_time) {
      const now = `${value.start_date} ${value.start_time}`;
      const then = `${value.end_date} ${value.end_time}`;
      const startTime = new Date(now).getTime();
      const endTime = new Date(then).getTime();
      const timeDifference = Math.abs(endTime - startTime);
      const hours = Math.floor(timeDifference / (1000 * 60 * 60));
      // const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
      if (hours <= 23) {
        setRepeatDisable(false);
        setRepeatText(() => t('repeat'));
      } else {
        setValue({ ...value, repeat: false });
        setRepeatDisable(true);
        setRepeatText(() => t('doesNotRepeat'));
      }
    }
  }, [dateTime]);

  useEffect(() => {
    dispatch(resetScheduleDisplaySignageState());
    dispatch(resetScheduleDisplayGroupState());
  }, [selectContents]);

  const openAdvanceModal = () => {
    setModalTitle(t('advanceSetting'));
    setModalView(<AdvanceSettings
      type="scheduleAdd"
      value={value}
      setValue={setValue}
      closeModal={setOpen}
      advanceSettingEdit
    />);
    setOpen(true);
  };
  // const handleSwitch = () => {
  //   setContentPreference(!contentPreference);
  // };
  return (
    <div className="content">
      <ModalBox
        status={notification}
        closeModal={setNotification}
        modalView={{ content: <Success message={t('scheduleCreatedSuccess')} /> }}
        notification
      />
      <div className="main-content">
        <TopContent
          label={[t('schedule'), t('createNewSchedule')]}
          buttonClass="success-button"
          button={false}
          buttonLabel="Back"
          click={addSchedule}
        />
        <ModalBox status={open} modalView={{ content: modalView, title: modalTitle }} closeModal={closeModal} />
        <div className="content-area">
          <div className="my-content">
            <div className="my-content-area-form-1">
              {error && <p className="text-danger message">{error}</p>}
              <div className="schedule-form">
                <div className="form-field-row">
                  <Input
                    icon={' '}
                    label={t('scheduleName')}
                    type="text"
                    name="name"
                    placeholder={t('enterScheduleName')}
                    required
                    value={value.name}
                    change={selectData}
                  />
                  <SelectBox
                    label={t('timeZone')}
                    name="time_zone"
                    required
                    value={value.time_zone}
                    onchange={selectData}
                    lists={timeZone}
                  />
                </div>
                <div className="form-select-field">
                  <Input
                    icon={' '}
                    label={t('description')}
                    type="text"
                    name="description"
                    placeholder={t('enterScheduleDescription')}
                    required={false}
                    value={value.description}
                    error={!descriptionValidation(99, value?.description) && submitButtonClicked}
                    errorMessage="Description should be less than 100 characters"
                    change={selectData}
                  />
                </div>
                <div className="form-field-row">
                  <div className="form-field-1">
                    <InputDate
                      label={t('scheduleFrom')}
                      name="start_date"
                      required
                      value={dateTime.start_date}
                      change={selectData}
                    />
                    <InputTime
                      label={t('startTime')}
                      name="start_time"
                      required
                      value={dateTime.start_time}
                      disabled={value.start_date === undefined}
                      change={selectData}
                    />
                  </div>
                  <div className="form-field-1">
                    <InputDate
                      label={t('scheduleTill')}
                      name="end_date"
                      required
                      value={dateTime.end_date}
                      change={selectData}
                      disabled={
                        value.start_date === undefined
                        || value.start_time === undefined
                      }
                      minDate={dateTime.start_date}
                    />
                    <InputTime
                      label={t('endTime')}
                      name="end_time"
                      required
                      value={dateTime.end_time}
                      change={selectData}
                      disabled={
                        value.start_date === undefined
                        || value.start_time === undefined
                        || value.end_date === undefined
                      }
                    />
                  </div>
                </div>
                {
                  checkPermission('repeatSchedule') && (
                    <div className="form-field-button">
                      <Button
                        label={repeatText}
                        classes="info-button"
                        click={openModalRepeat}
                        disabled={repeatDisable || !checkFeatures('schedule', 'repeat_schedule')}
                      />
                    </div>
                  )
                }
                {message && <div className="form-select-field info-text">{message}</div>}
                {
                  checkPermission('addSpecialSchedule') && (
                    <div className="form-select-field">
                      <FormGroup>
                        <FormControlLabel
                          control={(
                            <Checkbox
                              sx={{
                                color: '#8E8E8E',
                                '&.Mui-checked': {
                                  color: '#30A84B',
                                },
                              }}
                              onChange={setSpecialSchedule}
                              checked={value?.special}
                              disabled={!checkFeatures('schedule', 'create_special_schedule')}
                            />
                          )}
                          label={t('specialSchedule')}
                        />
                      </FormGroup>
                    </div>
                  )
                }
                <div className="form-select-field">
                  <MultiSelectBox
                    label={t('targetContent')}
                    buttonLabel={t('select')}
                    clickButton={openContentOptions}
                    required
                    name="contents"
                    addData={removeContentsTags}
                    placeholder={t('selectTargetContent')}
                    value={selectContents}
                    disabled={Object.keys(value)?.includes('layouts')}
                  />
                </div>
                {/* {checkFeatures('schedule', 'ai_content_recommendation_engine')
                  && (
                    <div className="content-preference-wrap">
                      <p>{t('contentPreferenceForAudience')}</p>
                      <Switch2
                        type="checkbox"
                        name="enable-two-factor"
                        change={() => handleSwitch()}
                        checked={contentPreference}
                        id={`toggle-checkbox-${contentPreference}`}
                      />
                    </div>
                  )} */}
                <div className="form-select-field">
                  <MultiSelectBox
                    label={t('targetDevice')}
                    buttonLabel={t('selectDevice')}
                    clickButton={openModalDevice}
                    required
                    name="devices"
                    addData={removeContentsTags}
                    placeholder={t('selectTargetDevice')}
                    value={selectDevices}
                    disabled={selectContents?.length === 0}
                  />
                </div>
                {value?.advanceSettings?.length > 0 ? (
                  <div>
                    <button
                      onClick={openAdvanceModal}
                      style={{ border: 'none', fontSize: '14px', background: 'none' }}
                      className="add-button borderless-button"
                      type="submit"
                    >
                      <AdvanceEditIcon />
                      <span>Advanced Settings</span>
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="form-button-group">
          <div className="form-button">
            <Button label={t('back')} click={addSchedule} classes="default-button" />
          </div>
          <div className="form-button">
            <Button
              label={uploading ? t('creating') : t('create')}
              click={submit}
              classes={uploading ? 'success-button loading-btn' : 'success-button'}
              disabled={disable}
              loading={uploading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default ScheduleAdd;

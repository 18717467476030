/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';

import TableForm from '../../../shared/component/TableForm';
import Button from '../../../shared/component/Button';

import contentHeader from '../../../assets/json/schedule-content-view-header.json';
import devicesHeader from '../../../assets/json/schedule-device-view-header.json';

import calender from '../../../assets/icons/calendar.png';
import time from '../../../assets/icons/timer.png';

import DataTable from '../../Table';
import scheduleApi from '../../../utils/api/schedule';

import { setErrorNotification } from '../../../redux/slices/NotificationSlice';

import { changeDateFormat, changeTimeFormat, convertBytes } from '../../../utils/helpers';

function PreviewContent(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { data } = props;
  const contentsData = useSelector((state) => state.myContent.contents);
  const devicesData = useSelector((state) => state.display.displaySignage);

  const [header, setHeader] = useState([]);
  const [tableValue, setValue] = useState([]);
  const [errorMessage, setError] = useState([]);

  // For material table
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [contentSelection, setContentSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({});
  const [scheduleDate, setScheduleDate] = useState({
    multi_day: false,
  });

  const back = () => {
    props?.back(props?.name, props?.data);
  };

  const columns = [
    {
      header: t('name'),
      accessorKey: 'name',
      enableColumnFilter: false,
    },
    {
      header: t('author'),
      accessorKey: 'author',
      enableColumnFilter: false,
    },
    {
      header: t('updateDate'),
      accessorKey: 'updateDate',
      enableColumnFilter: false,
    },
    {
      header: t('fileSize'),
      accessorKey: 'file_size',
      enableColumnFilter: false,
    },
  ];
  const submit = async () => {
    let availableDevice = {};
    if (props?.name === 'devices') {
      availableDevice.devices = tableValue.filter((val) => val.available_days_count > 0 && val.group_id === '')
        .map((val) => val.id);
      availableDevice.device_groups = [...new Set(tableValue.filter((val) => (
        val.available_days_count > 0 && val.group_id
      )).map((val) => val.group_id))];
      availableDevice.devices_list = props?.data?.devices_list
        ?.filter((device) => availableDevice?.devices?.includes(device?.id));
      availableDevice.device_groups_list = props?.data?.device_groups_list
        ?.filter((deviceGroup) => availableDevice?.device_groups?.includes(deviceGroup?.id));
      if (props?.calendar) {
        props?.closeModal(false);
      }
    } else {
      availableDevice = props?.data;
    }
    props?.selectData(props?.name, availableDevice);
    // props?.closeModal(props?.name === 'contents' ? false : true);
    if (props?.calendar) {
      props?.closeModal(false);
    }
    if (props?.name === 'contents'
      && props?.values?.devices?.length === 0
      && props?.values?.device_groups?.length === 0) {
      props?.closeModal(false);
    }
  };

  useEffect(() => {
    if (props?.values.name
      && props?.values.time_zone
      && props?.values.start_date
      && props?.values.end_date
      && props?.values.start_time
      && props?.values.end_time
    ) {
      if (props?.name === 'contents') {
        setHeader(contentHeader);
        setValue([
          ...data.contentsDetails,
          ...data.playlistDetails.map(
            (val) => ({
              ...val,
              name: `${val.name} (${val?.contents?.length})`,
              file_size: convertBytes(val?.totalFileSize),
            }),
          ),
        ]);
      } else if (props?.name === 'devices') {
        const tableHeader = devicesHeader;
        setHeader(tableHeader);
        const request = {
          name: props?.values.name,
          contents: ['6540ac0bc096ed20c7aec45c'],
          repeat: props?.values.repeat,
          start_only_date: props?.values.start_date,
          end_only_date: props?.values.end_date,
          start_time: props?.values.start_time,
          end_time: props?.values.end_time,
          time_zone: props?.values.time_zone,
          special: props?.values.special,
        };
        if (props?.data?.devices?.length > 0) {
          request.devices = props?.data?.devices;
        }
        if (props?.data?.device_groups?.length > 0) {
          request.device_groups = props?.data?.device_groups;
        }
        if (props?.values.repeat) {
          request.recurrences = {
            interval: props?.values.repeat_count,
            frequency: props?.values.repeat_type,
            start_only_date: props?.values.started_date,
            end_only_date: props?.values.complete_date,
          };
          if (props?.values.repeat_type === 'WEEKLY') {
            request.recurrences.by_week_day = props?.values.repeat_days;
          }
        }
        scheduleApi.checkConfig(request, props?.values.id).then((response) => {
          const allNonConflictsIds = response.data.nonConflicts.length > 0
            ? response.data.nonConflicts.map((val) => val.device) : [];
          const allConflictsIds = response.data.conflicts.length > 0
            ? response.data.conflicts.map((val) => val.device) : [];
          const endValue = devicesData.filter((vals) => [...allNonConflictsIds, ...allConflictsIds]
            .includes(vals.id)).map((vals) => {
            const availableDays = response?.data?.nonConflicts
              .filter((val1) => val1.device === vals.id)
              .map((val2) => ({
                name: changeDateFormat(val2.start_date_utc),
                time: `
                  ${changeTimeFormat(val2.device_start_time)}
                  to
                  ${changeTimeFormat(val2.device_end_time)}`,
                available: true,
                group_name: val2.group_name,
                group_id: val2.group_id,
              }));
            const unavailableDays = response?.data?.conflicts
              .filter((val1) => val1.device === vals.id)
              .map((val2) => ({
                name: changeDateFormat(val2.start_date_utc),
                time: `
                  ${changeTimeFormat(val2.device_start_time)}
                  to
                  ${changeTimeFormat(val2.device_end_time)}`,
                available: true,
                group_name: val2.group_name,
                group_id: val2.group_id,
              }));
            return {
              ...vals,
              available_days: availableDays,
              available_days_count: availableDays.length,
              available_time: availableDays.length > 0 ? availableDays[0]?.time : unavailableDays[0]?.time,
              group_name: availableDays.length > 0 ? availableDays[0]?.group_name : unavailableDays[0]?.group_name,
              group_id: availableDays.length > 0 ? availableDays[0]?.group_id : unavailableDays[0]?.group_id,
              unavailable_days: unavailableDays,
              unavailable_days_count: unavailableDays.length,
            };
          });
          setValue(endValue);
        }).catch((error) => {
          dispatch(setErrorNotification(error?.response?.data));
        });
      } else {
        setError('Name, Time Zone, Date and Time is required');
      }
    }
  }, [contentsData]);

  useEffect(() => {
    if ((props?.values?.start_date === props?.values?.end_date) && !props?.values?.repeat) {
      setScheduleDate({
        start_date: props?.values?.start_date,
        // end_date: props?.values?.end_date,
      });
    } else if ((props?.values?.start_date === props?.values?.end_date) && props?.values?.repeat) {
      setScheduleDate({
        start_date: props?.values?.start_date,
        end_date: props?.values?.complete_date,
      });
    } else {
      setScheduleDate({
        start_date: props?.values?.start_date,
        end_date: props?.values?.end_date,
        multi_day: true,
      });
    }
  }, [props?.values]);

  return (
    <>
      <div className="contents-list">
        {errorMessage && <p className="text-danger">{errorMessage}</p>}
        {!scheduleDate.multi_day
          && (
            <div className="schedule-details">
              <div className="schedule-details-list">
                <div className="image">
                  <img alt="image_icon" src={calender} />
                </div>
                <div className="schedule-date-view">
                  <span>
                    {scheduleDate?.start_date
                      && `${changeDateFormat(scheduleDate?.start_date)}`}
                  </span>
                  <span>
                    &nbsp;
                    {scheduleDate?.end_date
                      && `to ${changeDateFormat(scheduleDate?.end_date)}`}
                  </span>
                  {/* to
              ${changeDateFormat(props?.values.end_date)}`} */}
                </div>
              </div>
              <div className="schedule-details-list">
                <div className="image">
                  <img alt="image_icon" src={time} />
                </div>
                <div className="schedule-date-view">
                  <span>
                    {props?.values.start_date
                      && `${changeTimeFormat(`${props?.values.start_date} ${props?.values.start_time}`)}`}
                  </span>
                  <span>
                    &nbsp;
                    {props?.values.end_date
                      && `to ${changeTimeFormat(`${props?.values.start_date} ${props?.values.end_time}`)}`}
                  </span>
                  {/* to
              ${changeTimeFormat(`${props?.values.end_date} ${props?.values.end_time}`)}`} */}
                </div>
              </div>
            </div>
          )}
        {scheduleDate.multi_day
          && (
            <div className="schedule-details">
              <div className="schedule-details-list">
                <div className="image">
                  <img alt="image_icon" src={calender} />
                </div>
                <div className="schedule-date-view">
                  <span>
                    {scheduleDate?.start_date
                      && `${changeDateFormat(scheduleDate?.start_date)}`}
                  </span>
                  <span>
                    &nbsp;
                    {props?.values.start_time
                      && `- ${changeTimeFormat(`${props?.values.start_date} ${props?.values.start_time}`)}`}
                  </span>
                  {/* to
              ${changeDateFormat(props?.values.end_date)}`} */}
                </div>
              </div>
              <div className="schedule-details-list">
                <div className="image">
                  <img alt="image_icon" src={calender} />
                </div>
                <div className="schedule-date-view">
                  <span>
                    {scheduleDate?.end_date
                      && `${changeDateFormat(scheduleDate?.end_date)}`}
                  </span>
                  <span>
                    &nbsp;
                    {props?.values.end_date
                      && `- ${changeTimeFormat(`${props?.values.start_date} ${props?.values.end_time}`)}`}
                  </span>
                  {/* to
              ${changeTimeFormat(`${props?.values.end_date} ${props?.values.end_time}`)}`} */}
                </div>
              </div>
            </div>
          )}
        {props?.name === 'devices'
          && (
            <div>
              <div className="table-response">
                <InfiniteScroll
                  dataLength={tableValue?.length}
                  next={() => { }}
                  height="50vh"
                  hasMore={false}
                >
                  <TableForm
                    header={header}
                    value={tableValue}
                    checkBoxValue={[]}
                    checkedValues={[]}
                    class="border"
                  />
                </InfiniteScroll>
              </div>
              <p className="text-success">{t('onlyAvailableDaysWillBeAcceptedSchedule')}</p>
            </div>
          )}
        {props?.name === 'contents'
          && (
            <DataTable
              header={columns}
              value={tableValue}
              status="succeed"
              onFilterOrSortingChange={() => { }}
              totalPageCount={1}
              pageCount={1}
              scrollData={() => { }}
              totalDataCount=""
              setColumnFilters={setColumnFilters}
              setGlobalFilter={setGlobalFilter}
              setSorting={setSorting}
              columnFilters={columnFilters}
              globalFilter={globalFilter}
              rowSelection={contentSelection}
              setRowSelection={setContentSelection}
              columnVisibility={columnVisibility}
              setColumnVisibility={setColumnVisibility}
              sorting={sorting}
              enable={false}
              height="50vh"
              disableFilter
              disableSorting
              disableFullScreen
              disableFullScreenToggle
            // enableRowOrdering
            // setValue={setValue}
            />
          )}
      </div>
      <div className="form-button-group">
        <div className="form-button">
          <Button label={t('back')} click={back} classes="default-button" />
        </div>
        <div className="form-button">
          <Button label={t('continue')} click={submit} classes="success-button" />
        </div>
      </div>
    </>
  );
}

PreviewContent.propTypes = {
  data: PropTypes.objectOf().isRequired,
  values: PropTypes.objectOf().isRequired,
  name: PropTypes.string.isRequired,
  selectData: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  calendar: PropTypes.bool.isRequired,
  // makeAdvanceSettingsCall: PropTypes.func.isRequired,
};
export default PreviewContent;

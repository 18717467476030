import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import profile from '../../../assets/images/download.jpg';

import TopContent from '../../../shared/component/TopContent';
import { Input } from '../../../shared/component/form/Input';
import { checkFeatures, checkPermission } from '../../../utils/helpers';

function UserView() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const userData = useSelector((state) => state.users.users);
  const value = userData.find((val) => val.id === params?.id);

  useEffect(() => {
    if (userData.length === 0) {
      navigate('../');
    }
  }, [params]);

  const editUser = () => {
    navigate(`../edit/${params?.id}`);
  };

  return (
    <div className="content content-wrap">
      <div className="main-content">
        <TopContent
          label={[t('users'), value?.name]}
          buttonClass="default-button button-outline-success-1"
          button={checkPermission('editUser') && checkFeatures('user', 'edit')}
          buttonLabel={t('editUser')}
          click={editUser}
        />
        <div className="content-area">
          <div className="my-content">
            <div className="my-content-area-form">
              <div className="user-form-wrap">
                <div className="profile-upload" style={{ height: '100%' }}>
                  <label htmlFor="file" className="upload-click">
                    <img src={value?.signedUrl || profile} alt="profile" />
                  </label>
                </div>
                <div className="user-form" style={{ padding: '30px 25px' }}>
                  <div className="single-row">
                    <Input
                      icon={' '}
                      label={t('name')}
                      type="text"
                      name="name"
                      readonly
                      value={value?.name}
                    />
                    <Input
                      icon={' '}
                      label={t('username')}
                      type="text"
                      name="user_name"
                      readonly
                      value={value?.user_name}
                    />
                  </div>
                  <div className="single-row">
                    <Input
                      icon={' '}
                      label={t('mailID')}
                      type="text"
                      name="email"
                      readonly
                      value={value?.email}
                    />
                    <Input
                      icon={' '}
                      label={t('role')}
                      type="text"
                      name="role"
                      value={value?.role?.name}
                    />
                  </div>
                  <Input
                    icon={' '}
                    label={t('location')}
                    type="text"
                    readonly
                    name="location"
                    value={value?.location}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserView;

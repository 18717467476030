import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import YouTube from 'react-youtube';
import getYouTubeID from 'get-youtube-id';

import pdfIcon from '../../../assets/images/pdf-icon.png';

function ContentView(props) {
  const { t } = useTranslation();
  const value = props?.singleContent;
  const contentDetails = [
    {
      title: t('name'),
      content: value?.name,
    },
    {
      title: t('description'),
      content: value?.description,
    },
    {
      title: t('createdAt'),
      content: value?.createDate,
    },
    {
      title: t('author'),
      content: value?.author,
    },
    {
      title: t('tags'),
      content: value?.tags ? value.tags.join(', ') : '', // Convert tags array to comma-separated string
    },
  ];

  const handleViewPdf = () => {
    const newTab = window.open(value?.signedUrl, '_blank');
    if (newTab) {
      newTab.focus();
    }
  };
  return (
    <div className="content-view">
      <div className="content-file">
        {
          (() => {
            let preview;
            if (value?.file_type?.includes('video')) {
              preview = (
                <video
                  src={value?.signedUrl}
                  controls
                  poster={value?.thumbnailSignedUrls?.px_600x400}
                  preload="none"
                >
                  <track src="captions_en.vtt" kind="captions" srcLang="en" label="english_captions" />
                </video>
              );
            } else if (value?.file_type?.includes('image') || value?.file_type?.includes('template_html_url')
              || value?.file_type?.includes('template_html_content')) {
              preview = value?.signedUrl
                ? <img src={value?.signedUrl} alt="preview" /> : <img src={value?.url} alt="preview" />;
            } else if (value?.file_type?.includes('youtube')) {
              preview = (
                <YouTube
                  videoId={getYouTubeID(value?.url)}
                  opts={{
                    height: '100%',
                    width: '100%',
                  }}
                  className="youtube-video"
                  onReady={(e) => e.target.pauseVideo()}
                />
              );
            } else if (value?.file_type?.includes('pdf')) {
              preview = (
                <div
                  onClick={handleViewPdf}
                  className="pdf-icon-container"
                  role="presentation"
                >
                  <img
                    alt="pdf"
                    src={pdfIcon}
                    loading="lazy"
                    role="presentation"
                    className="pdf-icon"
                  />
                </div>
              );
            } else if (value?.file_type?.includes('url') && !value?.file_type?.includes('template_html_url')) {
              preview = (
                <iframe
                  src={value?.url}
                  title="url"
                />
              );
            }
            return preview;
          })()
        }
      </div>
      <div className="content-details">
        {value?.file_type === 'url'
          && (
            <div className="content-detail">
              <h2>URL</h2>
              <p>{value?.url}</p>
            </div>
          )}
        {contentDetails?.map((item) => {
          let preview;
          if (item?.content) {
            preview = (
              <div className="content-detail">
                <h2>{item?.title}</h2>
                <p>{item?.content}</p>
              </div>
            );
          }
          return preview;
        })}
      </div>
    </div>
  );
}
ContentView.propTypes = {
  singleContent: PropTypes.objectOf(PropTypes.string),
};
ContentView.defaultProps = {
  singleContent: {},
};

export default ContentView;

import React from 'react';
import PropTypes from 'prop-types';

function CustomSelect({
  onChangeValue,
  name,
  value,
  options,
}) {
  const handleChange = (event) => {
    const selectedValue = event.target.value;
    const selectedOption = options.find((option) => option.value === selectedValue);
    onChangeValue(name, selectedOption);
  };

  return (
    <div className="custom-select-container">
      <select value={value} onChange={handleChange}>
        {options && options.length > 0 ? (
          options.map((option) => (
            <option key={option?.value} value={option?.value}>
              {option?.label}
            </option>
          ))
        ) : (
          <option value="" disabled>
            No options available
          </option>
        )}
      </select>
    </div>
  );
}

CustomSelect.propTypes = {
  onChangeValue: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
};

CustomSelect.defaultProps = {
  value: '',
  options: [],
};

export default CustomSelect;

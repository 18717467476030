import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import cookies from 'js-cookie';
import validation from './templateFunctions';
import {
  AddItemIcon,
  BackIcon,
  CategoryIcon,
  DeleteIcon,
  EditTemplateNameIcon,
  SettingsActiveIcon,
  SideBarListMenu,
  SideBarSettingsIcon,
} from '../../../shared/component/svgIcon';
import uploadIcon from '../../../assets/icons/upload.png';
import Button from '../../../shared/component/Button';
import { checkFeatures, checkPermission } from '../../../utils/helpers';
import StyleRenderPage from './StyleRenderPage';
import templates from '../../../utils/api/templates';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import { updateCurrentTemplate } from '../../../redux/slices/TemplateSlice';
import ModalBox from '../../../shared/component/ModalBox';
import SaveAndTagsPopup from './SaveAndTagsPopup';

function TableDisplayEditor({ templateData }) {
  const [content, setContent] = useState([]);
  const columnTitle = content?.[0]?.template_contents?.column;
  const tableContents = content?.[0]?.template_contents?.items?.[0]?.details;
  const actions = content?.[0]?.template_contents?.action;
  const itemCount = content?.[0]?.template_contents?.items?.[0]?.count;
  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const [showStyle, setShowStyle] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [showAdd, setShowAdd] = useState(false);
  const [schema, setSchema] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [character, setCharacter] = useState(null);
  const [editFileName, setEditFileName] = useState(false);
  const [editTitle, setEditTitle] = useState(false);
  const fileNameRef = useRef(null);
  const titleRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currentLanguageCode = cookies?.get('i18next') || 'en';
  let parsingLocalData;

  useEffect(() => {
    if (templateData && Object.keys(templateData).length > 0) {
      localStorage.setItem('templateEditData', JSON.stringify(templateData));
    }
    const localData = localStorage.getItem('templateEditData');

    if (localData) {
      parsingLocalData = JSON.parse(localData);

      if (Object.keys(templateData).length === 0) {
        setContent([parsingLocalData]);
      } else {
        setContent([templateData]);
      }
    } else if (templateData && Object.keys(templateData).length > 0) {
      setContent([templateData]);
    }

    if (!checkPermission('getTemplatesList') || !checkFeatures('templates', 'create')) {
      setDisable(true);
    }
    const unloadCallback = (event) => {
      event.preventDefault();
      return '';
    };
    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, []);
  useEffect(() => {
    setTimeout(() => {
      if (templateData?.template_contents?.items?.[0]?.details?.length
        < templateData?.template_contents?.items?.[0]?.count) {
        setShowAdd(true);
        setSchema([templateData?.template_contents?.items?.[0]?.details?.[0]]);
      }
    }, []);
  }, []);
  const handleMouseOver = (index) => {
    setSelectedIndex(index);
  };
  const handleMouseOut = () => {
    setSelectedIndex(null);
  };
  const handleStyleClick = () => {
    setShowStyle(true);
    dispatch(updateCurrentTemplate(...content));
  };
  const handleMenuClick = () => {
    setShowStyle(false);
    dispatch(updateCurrentTemplate(...content));
  };
  const handleNameClick = (name) => {
    setSelectedColumn(name);
  };
  const handleNameChange = (e) => {
    const contentData = JSON.parse(JSON.stringify(content));
    if (e.target.value.length <= 25) {
      setValidationError(false);
      contentData[0].template_name = e.target.value;
      setContent(contentData);
      setSelectedColumn(null);
    } else if (e.target.value.length === 26) {
      setValidationError(true);
      setSelectedColumn('fileName');
      setCharacter(25);
    }
  };
  const handleBlurName = () => {
    setEditFileName(false);
    setValidationError(false);
  };
  const handleClick = (name, index) => {
    setEditIndex(index);
    setSelectedColumn(name);
    setValidationError(false);
  };
  const handleChange = (e, name, index) => {
    const validator = validation?.findValidation(content?.[0]?.template_type, name);
    if (name === selectedColumn && editIndex === index && e.target.value.length < validator) {
      setValidationError(false);
      const contentData = JSON.parse(JSON.stringify(content));
      const column = contentData?.[0]?.template_contents?.items?.[0]?.details?.[index];
      column[name] = e.target.value;
      setContent(contentData);
    } else if (name === selectedColumn && editIndex === index && e.target.value.length === validator) {
      setValidationError(true);
      setCharacter(validator);
    }
  };
  const handleImageUpload = (index) => {
    const fileInput = document.createElement('input');
    fileInput.setAttribute('type', 'file');
    fileInput.setAttribute('accept', 'image/*');
    fileInput.addEventListener('change', (e) => {
      const file = e.target.files[0];
      if (file) {
        const request = {
          'background-image': file,
        };
        setLoading(true);
        templates.updateTemplateImage(request).then((res) => {
          const contentData = JSON.parse(JSON.stringify(content));
          const column = contentData?.[0]?.template_contents?.items?.[0]?.details?.[index];
          column.image = res?.data?.background_image;
          setContent(contentData);
          setLoading(false);
        })
          .catch((error) => {
            dispatch(setErrorNotification(error?.response?.data));
            setLoading(false);
          });
      }
    });
    fileInput.click();
  };

  const handleDelete = (index) => {
    const contentData = JSON.parse(JSON.stringify(content));
    setValidationError(false);
    contentData?.[0]?.template_contents?.items?.[0]?.details?.splice(index, 1);
    setContent(contentData);
    if (tableContents.length - 1 < itemCount) {
      setShowAdd(true);
    }
    if (schema?.length <= 1) {
      setSchema([contentData?.[0]?.template_contents?.items?.[0]?.details?.[0]]);
    }
  };
  const handleBack = () => {
    const confirmChange = window.confirm('Are you sure? Changes may not be saved.');
    if (confirmChange) {
      dispatch(updateCurrentTemplate(...content));
      navigate('/admin/templates');
    }
  };
  const handleAddItem = () => {
    const newContent = {};
    setValidationError(false);
    if (tableContents?.length === itemCount - 1) {
      setShowAdd(false);
    }
    if (tableContents?.length < itemCount) {
      Object.keys(schema[0]).map((data) => {
        if (data === 'id') {
          newContent[data] = Math.random();
        }
        if (data === 'image') {
          newContent[data] = null;
        } else {
          newContent[data] = '...';
        }
        return newContent;
      });
      const contentData = JSON.parse(JSON.stringify(content));
      contentData?.[0]?.template_contents?.items?.[0]?.details?.push(newContent);
      setContent(contentData);
    }
  };

  const handleSaveTemplate = () => {
    setOpenModal(true);
  };
  const handleTitleClick = (title) => {
    setSelectedColumn(title);
  };
  const handleTitleChange = (e) => {
    const contentData = JSON.parse(JSON.stringify(content));
    if (e.target.value.length <= 30) {
      setValidationError(false);
      contentData[0].template_title = e.target.value;
      setContent(contentData);
      setSelectedColumn(null);
      if (e.target.value === '') {
        setEditTitle(true);
      }
    } else if (e.target.value.length > 30) {
      setSelectedColumn('title');
      setValidationError(true);
      setCharacter(30);
    }
  };
  const handleBlurTitle = () => {
    if (content?.[0]?.template_title !== '') {
      setEditTitle(false);
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && content?.[0]?.template_title !== '') {
      e.preventDefault();
      setEditTitle(false);
    }
  };
  useEffect(() => {
    if (fileNameRef.current) {
      fileNameRef?.current?.focus();
    }
  }, [editFileName]);
  useEffect(() => {
    if (titleRef?.current) {
      titleRef?.current?.focus();
    }
  }, [editTitle]);
  useEffect(() => () => localStorage.removeItem('templateEditData'), []);
  useEffect(() => {
    // Check if template_name is at least 1 character long
    setDisable(!content?.[0]?.template_name?.trim());
  }, [content]);
  return (
    <div className="template-table-display-editor">
      <ModalBox
        status={openModal}
        closeModal={setOpenModal}
        modalView={{
          title: t('saveTemplate'),
          content: <SaveAndTagsPopup
            setOpenModal={setOpenModal}
            content={content}
          />,
        }}
      />
      <div className="topBar">
        <div className="left">
          <div className="backIcon" onClick={handleBack} role="none">
            <BackIcon />
          </div>
          <div className="input-wrap">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {editFileName ? (
                <input
                  className="templateNameInput"
                  value={content?.[0]?.template_name}
                  onClick={(e) => handleNameClick(e, 'fileName')}
                  onChange={(e) => handleNameChange(e)}
                  onBlur={handleBlurName}
                  ref={fileNameRef}
                />
              )
                : (<span style={{ marginRight: '30px' }}>{content?.[0]?.template_name}</span>)}
              <div onClick={() => setEditFileName(true)} role="presentation" style={{ cursor: 'pointer' }}>
                <EditTemplateNameIcon />
              </div>
            </div>
            {(validationError && selectedColumn === 'fileName')
              && (
                <p className="errorTextTemplateName">
                  Template name should have min 1 max 25 characters
                </p>
              )}
          </div>
        </div>
        <div className="topBarButton">
          <Button
            label={t('save & preview')}
            classes="success-button"
            disabled={disable}
            click={handleSaveTemplate}
          />
        </div>
      </div>
      <div className="contentBodyContainer">
        <div
          className="bodySideBar"
          style={{
            borderLeft: currentLanguageCode === 'ar' ? '0.75px solid #9EA3A9' : '',
          }}
        >
          <div
            className={!showStyle ? 'sideBarMenuSelected' : 'sideBarMenu'}
            onClick={() => handleMenuClick()}
            role="presentation"
          >
            <div>
              {!showStyle ? <SideBarListMenu /> : <CategoryIcon />}
            </div>
            <p className={!showStyle ? 'sideBarContentSelected' : 'sideBarContent'}>Item List</p>
          </div>
          <div
            className={showStyle ? 'sideBarMenuSelected' : 'sideBarMenu'}
            onClick={() => handleStyleClick()}
            role="presentation"
          >
            <div>{showStyle ? <SettingsActiveIcon /> : <SideBarSettingsIcon />}</div>
            <p className={showStyle ? 'sideBarContentSelected' : 'sideBarContent'}>Settings</p>
          </div>
        </div>
        <div className="bodyWrapper" style={{ width: showStyle ? '' : '100%' }}>
          {(content?.[0]?.template_title || content?.[0]?.template_title === '') && !showStyle
            && (
              <div className="titleBox">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {editTitle ? (
                    <input
                      className="titleInput"
                      value={content?.[0]?.template_title}
                      onClick={(e) => handleTitleClick(e, 'title')}
                      onChange={(e) => handleTitleChange(e)}
                      onBlur={handleBlurTitle}
                      onKeyDown={handleKeyDown}
                      ref={titleRef}
                    />
                  )
                    : (<span style={{ marginRight: '20px' }}>{content?.[0]?.template_title}</span>)}
                  <div onClick={() => setEditTitle(true)} role="none" style={{ cursor: 'pointer' }}>
                    <EditTemplateNameIcon />
                  </div>
                </div>
                {(content?.[0]?.template_title === '' || (validationError && selectedColumn === 'title'))
                  && (
                    <p className="errorTextTitle">
                      Template title cannot be empty and must be no more than 30 characters
                    </p>
                  )}
              </div>
            )}
          {!showStyle
            && (
              <div className="contentContainer">
                {columnTitle?.map((data, column) => (
                  <div key={data?.title} className="contentWrapper">
                    <p className="title">{data.title}</p>
                    {tableContents?.map((value, index) => (
                      <div className="contentBox">
                        {data?.name === 'image' ? (
                          <div
                            className="contentImage"
                            onMouseOver={() => handleMouseOver(index)}
                            onFocus={() => handleMouseOver(index)}
                            onBlur={handleMouseOut}
                            onMouseOut={handleMouseOut}
                            onClick={() => handleImageUpload(index)}
                            role="none"
                            style={{ marginRight: currentLanguageCode === 'ar' ? '30px' : '' }}
                          >
                            <img
                              className={selectedIndex === index ? 'editImage' : 'default'}
                              src={value?.[data?.name] === null ? uploadIcon : value?.[data?.name]}
                              alt="item"
                            />
                            {selectedIndex === index && !loading
                              && <div className="editImageIcon"><EditTemplateNameIcon /></div>}
                            {selectedIndex === index && loading
                              && (
                                <div className="editImageIcon">
                                  <CircularProgress
                                    size={20}
                                    thickness={5}
                                    color="info"
                                  />
                                </div>
                              )}
                          </div>
                        ) : (
                          <div>
                            {value?.[data?.name] !== false
                              && (
                                <textarea
                                  className="contentInput"
                                  value={value?.[data?.name]}
                                  onClick={() => handleClick(data?.name, index)}
                                  onChange={(e) => handleChange(e, data?.name, index)}
                                />
                              )}
                            {(validationError && data?.name === selectedColumn && editIndex === index)
                              && (
                                <p className="errorText">
                                  {`${data?.name} should have only ${character - 1} characters`}
                                </p>
                              )}
                          </div>

                        )}
                      </div>
                    ))}
                    {(showAdd && column === 0)
                      && (
                        <div className="addItemIcon" onClick={handleAddItem} role="none">
                          <AddItemIcon />
                        </div>
                      )}
                  </div>
                ))}
                {(tableContents?.length > 1 && actions)
                  && (
                    <div className="contentWrapper">
                      <p className="title">Action</p>
                      {tableContents?.map((data, index) => (
                        <div className="deleteIcon" onClick={() => handleDelete(index)} role="none">
                          <DeleteIcon />
                        </div>
                      ))}
                    </div>
                  )}
              </div>
            )}
        </div>
        {showStyle
          && (
            <StyleRenderPage setData={setContent} />
          )}
      </div>
    </div>
  );
}
TableDisplayEditor.propTypes = {
  templateData: PropTypes.arrayOf.isRequired,
};
export default TableDisplayEditor;

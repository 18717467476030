import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  fetchSubscriptionList,
  setFilter,
  setTableFilter,
} from '../../../redux/slices/SubscriptionSlice';
import DataTable from '../../Table';
import height from '../../../utils/size-variables';
import { capitalize } from '../../../utils/helpers';

function SubscriptionSummary() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    subscriptions,
    pageCount,
    totalPageCount,
    status,
    filter,
    tableFilter,
    totalDataCount,
  } = useSelector((state) => state.subscription);

  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({});
  const [isFirstRender, setIsFirstRender] = useState(true);

  const getSubscriptions = (counts, filters = {}) => {
    if (status === 'succeed') {
      dispatch(fetchSubscriptionList({ page: counts, limit: 20, ...filters }));
    }
  };

  const handleScroll = () => {
    getSubscriptions(pageCount + 1, filter);
  };

  const columns = [
    {
      header: t('sNo'),
      size: 60,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      Cell: ({ row }) => row.index + 1,
    },
    {
      header: t('subscriptionId'),
      accessorKey: 'order_id',
    },
    {
      header: t('eventType'),
      accessorKey: 'event',
    },
    {
      header: t('date'),
      accessorKey: 'createdAt',
      accessorFn: (originalRow) => new Date(originalRow.createdAt),
      filterVariant: 'date-range',
      enableColumnFilter: false,
      Cell: ({ row }) => row.original.request_date,
    },
    {
      header: t('storage'),
      accessorKey: 'storage',
    },
    {
      header: t('devices'),
      accessorKey: 'devices_count',
      Cell: ({ row }) => row.original.devices_count,
    },
    {
      header: t('price'),
      accessorKey: 'total_price',
      Cell: ({ row }) => row.original.price,
    },
    {
      header: t('status'),
      accessorKey: 'status',
      Cell: ({ row }) => capitalize(row.original.status),
      enableColumnFilter: false,
      enableSorting: false,
    },
  ];

  const onFilterOrSortingChange = (filteredValues, reduxColumnFiltersSorting) => {
    dispatch(setTableFilter(reduxColumnFiltersSorting));
    dispatch(setFilter(filteredValues));
    getSubscriptions(1, filteredValues);
  };

  useEffect(() => {
    setIsFirstRender(false);
    getSubscriptions(1);
  }, []);
  return (
    <div>
      <DataTable
        header={columns}
        value={subscriptions}
        status={status}
        onFilterOrSortingChange={onFilterOrSortingChange}
        reduxColumnFiltersSorting={tableFilter}
        isFirstRender={isFirstRender}
        totalPageCount={totalPageCount}
        pageCount={pageCount}
        scrollData={handleScroll}
        totalDataCount={totalDataCount}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        columnVisibility={columnVisibility}
        setColumnVisibility={setColumnVisibility}
        enable={Object.keys(filter).filter((val) => val !== 'sortField' && val !== 'sortBy').length > 0}
        height={height.halfTableHeight}
      />
    </div>
  );
}

export default SubscriptionSummary;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { LayoutPopupCloseIcon } from '../../../shared/component/svgIcon';
import { setDeviceType } from '../../../redux/slices/ContentLayoutSlice';

function LayoutPopup({ open, onClose }) {
  const [selectedOption, setSelectedOption] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleOptionChange = (event, index) => {
    setSelectedOption(event.target.value);
    dispatch(setDeviceType(index));
  };
  const { t } = useTranslation();

  const handleContinue = () => {
    // Handle continue action here
    navigate('/admin/content-layout/custom');
    onClose();
  };

  // If `open` is false, don't render the popup
  if (!open) return null;

  const layoutOptions = [
    { label: 'Landscape (1920 x 1080px)', value: 'landscape' },
    { label: 'Portrait (1080 x 1920px)', value: 'portrait' },
    { label: 'Bar Display (1920 x 158px)', value: 'bar' },
    { label: 'Shelf Display (1920 x 360px)', value: 'shelf' },
  ];

  return (
    <div className="custom-layout-overlay">
      <div className="layout-popup">
        <div className="header">
          <h2 className="title">{t('layoutType')}</h2>
        </div>
        <div role="none" onClick={onClose} className="close-button">
          <LayoutPopupCloseIcon />
        </div>
        <div className="layout-content">
          {
            layoutOptions?.map((option, index) => (
              <label key={option?.value} htmlFor={option?.value} className="option">
                <input
                  type="radio"
                  id={option?.value}
                  name="layoutOption"
                  value={option?.label}
                  checked={selectedOption === option?.label}
                  onChange={(e) => handleOptionChange(e, index)}
                  className="radio-input"
                />
                {option?.label}
              </label>
            ))
          }
        </div>
        <div className="popup-footer">
          <button
            type="button"
            onClick={handleContinue}
            className={`continue-button ${selectedOption ? 'enabled' : ''}`}
            disabled={!selectedOption}
          >
            {t('continue')}
          </button>
        </div>
      </div>
    </div>
  );
}

// Define PropTypes for the component to ensure `open` and `onClose` are correctly used
LayoutPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default LayoutPopup;

/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toPng } from 'html-to-image';
import ContentLayoutHeader from './ContentLayoutHeader';
import {
  CloseIconWithoutBorder,
  LayoutEditIcon,
  PlusIcon,
} from '../../../shared/component/svgIcon';
import ModalBox from '../../../shared/component/ModalBox';
import FileTypesPopup from './FileTypesPopup';

import imageIcon from '../../../assets/images/image-icon.png';
import videoIcon from '../../../assets/images/video-icon.png';
import youtubeIcon from '../../../assets/images/youtube-icon.png';
import pdfIcon from '../../../assets/images/pdf-icon.png';
import urlIcon from '../../../assets/images/url-image.png';
import playlistIcon from '../../../assets/images/playlist-icon.png';
import { capitalize, checkFeatures, checkPermission } from '../../../utils/helpers';
import contentLayout from '../../../utils/api/content-layout';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import { createSavedLayout } from '../../../redux/slices/SavedContentLayoutSlice';
import ClockPreview from './Widgets/ClockPreview';
import WeatherPreview from './Widgets/WeatherPreview';
import SelectedWidgetPopup from './SelectWidgetPopup';
import RSSPreview from './Widgets/RSSPreview';
import { resetContentLayoutWidgets } from '../../../redux/slices/ContentLayoutWidgetsSlice';

function ContentLayoutAdd() {
  const params = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const gridRef = useRef();
  const { availableContentLayout } = useSelector((state) => state.contentLayout);

  const [videoAllowedGrid, setVideoAllowedGrid] = useState(null);
  const [openModalBox, setOpenModalBox] = useState(false);
  const [selectedLayoutName, setSelectedLayoutName] = useState();
  const [selectedLayout, setSelectedLayout] = useState({});
  const [selectedLayoutStructure, setSelectedLayoutStructure] = useState([]);
  const [modalView, setModalView] = useState({});
  const [buttonLoading, setButtonLoading] = useState(false);

  const fileTypeAllowedInOneSection = [
    {
      label: t('video'),
      key: 'video',
    },
    {
      label: t('playlist'),
      key: 'playlist',
    },
    {
      label: t('pdf'),
      key: 'pdf',
    },
    {
      label: t('youtubeUrl'),
      key: 'youtube',
    },
  ];

  const availableMediaTypes = [
    {
      label: t('image'),
      key: 'image',
    },
    {
      label: t('video'),
      key: 'video',
    },
    {
      label: t('playlist'),
      key: 'playlist',
    },
    {
      label: t('pdf'),
      key: 'pdf',
    },
    {
      label: t('url'),
      key: 'url',
    },
    {
      label: t('youtubeUrl'),
      key: 'youtube',
    },
    {
      label: t('template'),
      key: 'template',
    },
  ];

  const availableWidgets = [
    {
      label: t('weather'),
      key: 'weather',
    },
    {
      label: t('clock'),
      key: 'clock',
    },
    {
      label: 'RSS Feed',
      key: 'rss',
    },
  ];

  const fileTypeImage = (type) => {
    switch (type) {
      case 'video':
        return videoIcon;
      case 'pdf':
        return pdfIcon;
      case 'url':
        return urlIcon;
      case 'youtube':
        return youtubeIcon;
      case 'playlist':
        return playlistIcon;
      default:
        return imageIcon;
    }
  };

  const handleAddWidgetInLayout = (updatedWidgetInLayout, selectedWidget) => {
    setSelectedLayoutStructure((prevValue) => prevValue?.map((eachGrid) => {
      let updatedLayoutStructure = eachGrid;
      if (updatedLayoutStructure?._id === updatedWidgetInLayout?._id) {
        updatedLayoutStructure = {
          ...updatedWidgetInLayout,
          contents: [selectedWidget?._id],
          selectedWidget,
        };
      }
      return updatedLayoutStructure;
    }));
    setOpenModalBox(false);
  };

  const handleSetFileType = (updatedFileTypeInLayout) => {
    if (availableWidgets
      .map((eachMediaTyp) => eachMediaTyp.key)
      .some((key) => updatedFileTypeInLayout?.file_types?.includes(key))) {
      dispatch(resetContentLayoutWidgets());
      setModalView({
        title: t('selectWidget'),
        content: <SelectedWidgetPopup
          selectedWidgetType={updatedFileTypeInLayout?.file_types?.[0]}
          onClickSubmit={handleAddWidgetInLayout}
          selectedLayout={updatedFileTypeInLayout}
          onClickPrevious={() => setOpenModalBox(false)}
        />,
      });
    } else {
      setSelectedLayoutStructure((prevValue) => prevValue?.map((eachGrid) => {
        let updatedLayoutStructure = eachGrid;
        if (updatedLayoutStructure?._id === updatedFileTypeInLayout?._id) {
          updatedLayoutStructure = updatedFileTypeInLayout;
        }
        return updatedLayoutStructure;
      }));
      setOpenModalBox(false);
      if (fileTypeAllowedInOneSection
        ?.map((eachMediaTyp) => eachMediaTyp.key)
        ?.some((key) => updatedFileTypeInLayout?.file_types?.includes(key))) {
        setVideoAllowedGrid(updatedFileTypeInLayout?._id);
      } else if (videoAllowedGrid === updatedFileTypeInLayout?._id) {
        setVideoAllowedGrid(null);
      }
    }
  };

  const handleSelectFileType = (singleGridLayout, event = '') => {
    if (singleGridLayout?.file_types?.length === 0 || event === 'edit') {
      setOpenModalBox(true);
      setModalView({
        title: t('addFileType'),
        content: <FileTypesPopup
          closeModal={() => setOpenModalBox(false)}
          singleGridLayout={singleGridLayout}
          handleSetFileType={handleSetFileType}
          availableMediaTypes={availableMediaTypes}
          availableWidgets={availableWidgets}
          fileTypeAllowedInOneSection={fileTypeAllowedInOneSection}
          videoAllowedGrid={videoAllowedGrid}
        />,
      });
    }
  };

  const handleRemoveFileType = (gridId, typeToRemove, widget = false) => {
    setSelectedLayoutStructure((prevValue) => prevValue?.map((eachGridStructure) => {
      let updatedGridStructure = eachGridStructure;
      if (eachGridStructure?._id === gridId) {
        if (widget) {
          updatedGridStructure = {
            ...eachGridStructure,
            file_types: [],
            contents: [],
          };
        } else {
          updatedGridStructure = {
            ...eachGridStructure,
            file_types: eachGridStructure?.file_types?.filter((fileType) => fileType !== typeToRemove),
          };
        }
        if (fileTypeAllowedInOneSection
          ?.map((eachMediaTyp) => eachMediaTyp.key)
          ?.some((key) => updatedGridStructure?.file_types?.includes(key))) {
          setVideoAllowedGrid(updatedGridStructure?._id);
        } else if (videoAllowedGrid === updatedGridStructure?._id) {
          setVideoAllowedGrid(null);
        }
      }
      return updatedGridStructure;
    }));
  };

  const dataURLtoFile = (dataUrl, filename) => {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    const n = bstr.length;
    const u8arr = new Uint8Array(n);

    for (let i = 0; i < n; i += 1) {
      u8arr[i] = bstr.charCodeAt(i);
    }

    return new File([u8arr], filename, { type: mime });
  };
  const convertBase64 = () => toPng(gridRef?.current, { cacheBust: true })
    .then((base64Image) => base64Image)
    .catch(() => '');

  const handleSubmitFunction = async () => {
    setButtonLoading(true);
    try {
      const gridImageBase64 = await convertBase64();
      const finalDataToSave = {
        name: selectedLayoutName,
        orientation: selectedLayout?.orientation,
        device_layouts: selectedLayout?.device_layouts,
        image: gridImageBase64 ? dataURLtoFile(gridImageBase64, selectedLayoutName) : undefined,
        layouts: selectedLayoutStructure?.map((layoutStructure) => ({
          grid_column_start: layoutStructure?.grid_column_start,
          grid_column_end: layoutStructure?.grid_column_end,
          grid_row_start: layoutStructure?.grid_row_start,
          grid_row_end: layoutStructure?.grid_row_end,
          file_types: layoutStructure?.file_types,
          contents: layoutStructure?.contents,
          _id: layoutStructure?._id,
          name: layoutStructure?.name,
          partition_id: layoutStructure?.partition_id,
        })),
      };

      const savedLayoutData = await contentLayout.addSavedLayout(finalDataToSave);

      // Ensure orientation is set
      const layoutDataToDispatch = {
        ...savedLayoutData?.data,
        orientation: savedLayoutData?.data?.orientation ?? finalDataToSave.orientation,
      };

      dispatch(createSavedLayout(layoutDataToDispatch));
      setButtonLoading(false);
      navigate('/admin/content-layout/saved');
    } catch (error) {
      setButtonLoading(false);
      if (error?.response?.data) {
        dispatch(setErrorNotification(error?.response?.data));
      }
    }
  };

  useEffect(() => {
    setSelectedLayoutStructure(selectedLayout?.layouts);
    setSelectedLayoutName(selectedLayout?.name);
  }, [selectedLayout]);

  useEffect(() => {
    if (availableContentLayout?.length > 0) {
      setSelectedLayout(availableContentLayout?.find((layout) => layout?._id === params?.id));
    } else {
      contentLayout.getAvailableContentLayoutById(params?.id)
        .then((res) => {
          setSelectedLayout({
            ...res?.data,
            id: res?.data?._id,
            layouts: res?.data?.layouts.map((eachLayout) => ({
              ...eachLayout,
              file_types: [],
            })),
          });
        })
        .catch((err) => {
          dispatch(setErrorNotification(err?.response?.data));
        });
    }
  }, [params]);

  return (
    <div className="content-layout-add-wrap">
      <ContentLayoutHeader
        handlePreviousClick={() => navigate('/admin/content-layout/layouts')}
        handleSubmitFunction={handleSubmitFunction}
        selectedLayoutName={selectedLayoutName}
        setSelectedLayoutName={setSelectedLayoutName}
        buttonLoading={buttonLoading}
        disabled={!checkFeatures('layouts', 'create') || !checkPermission('addLayouts')}
      />
      <ModalBox
        status={openModalBox}
        closeModal={() => setOpenModalBox(false)}
        modalView={modalView}
      />
      <div className="content-layout-add-container">
        <div className="content-layout-grid" ref={gridRef}>
          {selectedLayoutStructure?.map((eachLayout, index) => (
            <div
              className="each-layout-wrap"
              style={{
                gridColumnStart: eachLayout?.grid_column_start,
                gridColumnEnd: eachLayout?.grid_column_end,
                gridRowStart: eachLayout?.grid_row_start,
                gridRowEnd: eachLayout?.grid_row_end,
              }}
              role="presentation"
              onClick={() => handleSelectFileType(eachLayout)}
            >
              <h5>{t('section')} {index + 1}</h5>
              {
                (() => {
                  let gridContent;
                  if (eachLayout?.file_types?.length === 0) {
                    gridContent = (
                      <div className="each-layout-add-content">
                        <span>
                          <PlusIcon
                            width="56"
                            height="56"
                            color="#CED0D3"
                          />
                        </span>
                        <p>{t('addNew')}</p>
                      </div>
                    );
                  } else if (availableMediaTypes
                    ?.map((eachMediaTyp) => eachMediaTyp.key)
                    ?.some((key) => eachLayout?.file_types?.includes(key))) {
                    gridContent = (
                      <div className="file_type_wrap">
                        {eachLayout?.file_types?.map((type) => (
                          <div className="each_file_type">
                            <img src={fileTypeImage(type)} alt="" className="file_type_image" />
                            <p>{capitalize(type)}</p>
                            <div
                              className="close-icon"
                              role="presentation"
                              onClick={() => handleRemoveFileType(eachLayout?._id, type)}
                            >
                              <CloseIconWithoutBorder
                                height="16"
                                width="16"
                                color="#000000"
                              />
                            </div>
                          </div>
                        ))}
                        {eachLayout?.file_types?.length > 0
                          && (
                            <div
                              className="header-edit-wrap"
                              role="presentation"
                              onClick={() => handleSelectFileType(eachLayout, 'edit')}
                            >
                              <LayoutEditIcon />
                              <span>{t('edit')}</span>
                            </div>
                          )}
                      </div>
                    );
                  } else if (availableWidgets
                    ?.map((eachMediaTyp) => eachMediaTyp.key)
                    ?.some((key) => eachLayout?.file_types?.includes(key))) {
                    gridContent = (
                      <div className="widget-wrap">
                        {
                          (() => {
                            let widgetPreview;
                            if (eachLayout?.file_types?.[0] === 'weather') {
                              widgetPreview = (
                                <WeatherPreview
                                  gridName={`section${index}`}
                                  selectedWidget={eachLayout?.selectedWidget}
                                />
                              );
                            } else if (eachLayout?.file_types?.[0] === 'clock') {
                              widgetPreview = (
                                <ClockPreview
                                  gridName={`section${index}`}
                                  selectedWidget={eachLayout?.selectedWidget}
                                />
                              );
                            } else if (eachLayout?.file_types?.[0] === 'rss') {
                              widgetPreview = (
                                <RSSPreview
                                  gridName={`section${index}`}
                                  selectedWidget={eachLayout?.selectedWidget}
                                />
                              );
                            }
                            return widgetPreview;
                          })()
                        }
                        <span
                          className="absolute-wrap"
                          role="presentation"
                          onClick={() => handleRemoveFileType(eachLayout?._id, '', true)}
                        >
                          <CloseIconWithoutBorder
                            height="16"
                            width="16"
                            color="#000000"
                          />
                        </span>
                      </div>
                    );
                  }
                  return gridContent;
                })()
              }
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ContentLayoutAdd;

/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */
import React, { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { toPng } from 'html-to-image';
import saveApi from '../../../../utils/api/widget';
import { createWidget, updateWidget } from '../../../../redux/slices/WidgetTempSlice';
import { setErrorNotification } from '../../../../redux/slices/NotificationSlice';
import ModalBox from '../../../../shared/component/ModalBox';
import Success from '../../../../shared/component/Success';
import Button from '../../../../shared/component/Button';
import arrowLeftIcon from '../../../../assets/icons/arrow-left.png';
import IFrameComponent from '../../../../shared/component/IFrameComponent';

function RSSFeedImagePage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const widgets = useSelector((states) => states.widgets.selectedWidget);
  const ref = useRef(null);
  const isEditMode = state?.widgetEditId ?? false;
  const [uploading, setUploading] = useState(false);
  const [notification, setNotification] = useState(false);
  const navigate = useNavigate();
  const navigateBack = () => {
    if (!uploading) {
      navigate(-1);
    }
  };
  const dataURLtoFile = (dataUrl, filename) => {
    const arr = dataUrl?.split(',');
    const mime = arr?.[0]?.match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr?.length;
    const u8arr = new Uint8Array(n);
    while (n > -1) {
      u8arr[n] = bstr?.charCodeAt(n);
      n -= 1;
    }
    return new File([u8arr], filename, { type: mime });
  };

  const convertBase64 = async () => {
    try {
      const dataUrl = await toPng(ref?.current, { cacheBust: true });
      const binaryUrl = dataURLtoFile(dataUrl, 'widgets');
      return binaryUrl;
    } catch (error) {
      // setUploading(false);
      // throw new Error(error?.text);
      return '';
    }
  };
  const submit = async () => {
    try {
      setUploading(true);
      const previewImage = await convertBase64();
      let rssDisplayType;
      if (state?.displayUnit === 'ImageBoard') {
        rssDisplayType = 'image_board';
      } else if (state?.displayUnit === 'Ticker') {
        rssDisplayType = 'ticker';
      }
      const params = {
        name: state?.name,
        rss_display_type: rssDisplayType,
        rss_feed_url: state?.feedUrl,
        duration: state?.duration,
        widget_templates_id: state?.id,
        widget_id: widgets?._id,
      };
      if (previewImage) {
        params.preview_image = previewImage;
      }
      if (isEditMode) {
        params.widget_id = widgets?.widget_id?._id;
        params.widget_type = 'rss';
        saveApi?.updateWidget(state?.widgetEditId, params).then((res) => {
          dispatch(updateWidget(res?.data));
          setUploading(false);
          setNotification(true);
          setTimeout(() => {
            setNotification(false);
            navigate('/admin/apps/saved-widgets');
          }, 3000);
        })
          .catch((error) => {
            setUploading(false);
            dispatch(setErrorNotification(error.message));
          });
      } else {
        saveApi?.createWidgetTemplate(params)
          .then((res) => {
            dispatch(createWidget(res?.data));
            setUploading(false);
            setNotification(true);
            setTimeout(() => {
              setNotification(false);
              navigate('/admin/apps/saved-widgets');
            }, 3000);
          })
          .catch((error) => {
            setUploading(false);
            dispatch(setErrorNotification(error.response.data));
          });
      }
    } catch (error) {
      dispatch(setErrorNotification(error.message));
    }
  };

  return (
    <div>
      <div className="widget-preview">
        <ModalBox
          status={notification}
          closeModal={setNotification}
          notification
          modalView={{ content: <Success message={t('widgetCreatedSuccessfully')} /> }}
        />
        <div className="weather-container">
          <div
            className="back-button"
            role="presentation"
            onClick={() => navigateBack()}
            style={{
              cursor: uploading ? 'not-allowed' : 'pointer',
              pointerEvents: uploading ? 'none' : 'auto',
            }}
          >
            <div className="back-arrow-icon">
              <img src={arrowLeftIcon} alt="left-arrow" className="left-arrow-icon" />
            </div>
          </div>
          <div
            className="save-button"
          >
            <Button
              label={uploading ? t('saving') : t('save')}
              classes="success-button"
              click={submit}
              loading={uploading}
              disabled={uploading}
            />
          </div>
        </div>
        <div className="widget-content" ref={ref}>
          <IFrameComponent
            htmlUrl={state?.html_url}
            jsUrl={state?.script_url}
            title="rss-preview"
            replacements={[
              ['duration', state?.duration],
              ['rss_display_type', state?.displayUnit === 'Ticker' ? 'ticker' : 'image_board'],
              ['rss_feed_url', state?.feedUrl],
            ]}
          />
        </div>
      </div>
    </div>
  );
}

export default RSSFeedImagePage;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import DataTable from '../../Table';
import { fetchDeviceScheduleList } from '../../../redux/slices/DisplaySignageSlice';

function DeviceSchedule(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    displaySignage,
    deviceSchedules,
    scheduleStatus,
  } = useSelector((state) => state.display);

  const deviceName = displaySignage?.find((item) => item?.id === props?.deviceId)?.name;

  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({});

  const columns = [
    {
      header: t('sNo'),
      size: 60,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      Cell: ({ row }) => row.index + 1,
    },
    {
      header: t('scheduleName'),
      accessorKey: 'name',
      enableColumnFilter: false,
      Cell: ({ row }) => (
        <div className="status-wrap">
          {row.original?.eventType === 'ongoing'
            ? <div className="status-ongoing" />
            : <div className="status-upcoming" />}
          <span>{row.original?.store_name}</span>
        </div>
      ),
    },
    {
      header: t('scheduledDate'),
      accessorKey: 'scheduleDate',
      enableColumnFilter: false,
    },
    {
      header: t('scheduledTime'),
      accessorKey: 'scheduledTime',
      enableColumnFilter: false,
    },
    {
      header: t('timeZone'),
      accessorKey: 'time_zone',
      enableColumnFilter: false,
    },
  ];

  useEffect(() => {
    dispatch(fetchDeviceScheduleList(props?.deviceId));
  }, []);

  return (
    <div className="device-schedule">
      <p>{t('belowListedAreOnlyOn-going&UpcomingSchedulesOf')}&nbsp;{deviceName}</p>
      <div className="status-color-details">
        <div className="status-color-detail">
          <div className="ongoing" />
          <span>{t('upcoming')}</span>
        </div>
        <div className="status-color-detail">
          <div className="upcoming" />
          <span>{t('on-going')}</span>
        </div>
      </div>
      <div className="table-container">
        <DataTable
          header={columns}
          value={deviceSchedules}
          status={scheduleStatus}
          totalPageCount={1}
          pageCount={1}
          onFilterOrSortingChange={() => {}}
          scrollData={() => { }}
          totalDataCount={0}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          columnVisibility={columnVisibility}
          setColumnVisibility={setColumnVisibility}
          enable=""
          height="30vh"
          disableFilter
          disableSorting
          disableFullScreenToggle
          disableFilterButton
        />
      </div>
    </div>
  );
}

DeviceSchedule.propTypes = {
  deviceId: PropTypes.string.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      eventType: PropTypes.string,
      store_name: PropTypes.string,
    }),
  }),
};
DeviceSchedule.defaultProps = {
  row: null,
};

export default DeviceSchedule;

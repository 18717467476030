import React from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import cookies from 'js-cookie';

export const getOicStorageKey = () => {
  const authSettings = cookies.get('secure-auth-access');
  if (authSettings) {
    return authSettings;
  }
  return null;
};

export const getWejhaInfo = () => {
  const key = getOicStorageKey();
  if (key) {
    const oic = JSON.parse(key);
    return oic;
  }
  return null;
};

export const decodeJWTToken = (token) => {
  const payload = JSON.parse(atob(token.split('.')[1]));
  return payload;
};

export const getToken = () => {
  const wejha = getWejhaInfo();
  if (wejha) {
    return {
      Authorization: `Bearer ${wejha}`,
      // 'content-type': 'multipart/form-data',
    };
  }
  return null;
};

function formatDate(date, format) {
  let value = '';
  if (format === 'time') {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours %= 12;
    hours = hours || 12; // the hour '0' should be '12'
    hours = hours < 10 ? `0${hours.toString()}` : hours;
    minutes = minutes < 10 ? `0${minutes.toString()}` : minutes;
    const strTime = `${hours} : ${minutes} ${ampm}`;
    value = strTime;
  } else {
    value = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  }
  return value;
}

export const changeDateFormat = (date) => {
  const time = formatDate(new Date(date), 'date');
  return time;
};

export const changeTimeFormat = (date) => {
  const time = formatDate(new Date(date), 'time');
  return time;
};

export const changeDateTimeFormat = (date) => {
  const time = `${changeDateFormat(date)} ${changeTimeFormat(date)}`;
  return time;
};

export const getTimeDuration = (startDate, endDate) => {
  const startTime = new Date(startDate).getTime();
  const endTime = new Date(endDate).getTime();
  const timeDifference = Math.abs(endTime - startTime);
  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  return `${hours} hrs ${minutes} mins`;
};

export const getDatesInRange = (startDate, endDate) => {
  const date = new Date(startDate.getTime());
  const dates = [];
  while (date <= endDate) {
    dates.push(new Date(date).toLocaleDateString());
    date.setDate(date.getDate() + 1);
  }
  return dates;
};

// Function to generate a random color
export function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i += 1) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function convertBytes(totalBytes) {
  let size;
  if (totalBytes < 1000000) {
    size = (`${(totalBytes / 1024).toFixed(2)} KB`);
  } else if (totalBytes < 1047511424) {
    size = (`${(totalBytes / 1024 / 1024).toFixed(2)} MB`);
  } else {
    size = (`${(totalBytes / 1024 / 1024 / 1024).toFixed(2)} GB`);
  }
  return size;
}

export function convertToMBorGB(value) {
  let finalValue;
  if (value >= 1) {
    finalValue = `${value?.toFixed(2)} GB`;
  } else {
    finalValue = `${(value * 1024)?.toFixed(2)} MB`;
  }
  return finalValue;
}

export function setLocalStorageItem(name, data) {
  if (name && data) {
    localStorage.setItem(name, JSON.stringify(data));
  }
}

export function setCookieStorageItem(name, data) {
  cookies.set(name, JSON.stringify(data));
}

export function getLocalStorageItem(name) {
  const data = JSON.parse(localStorage.getItem(name));
  return data;
}

export function getCookieStorageItem(name) {
  const cookieValue = cookies.get(name);
  if (cookieValue) {
    const data = JSON?.parse(cookieValue);
    return data;
  }
  return null;
}

export function removeLocalStorageItem(name) {
  localStorage.removeItem(name);
}

export function removeCookieStorageItem(name) {
  cookies.remove(name);
}

export function checkPermission(permission) {
  const data = JSON.parse(localStorage.getItem('role'));
  return data?.includes(permission);
}

// Check if the feature available or not based on feature_name and sbu_feature_name
export function checkFeatures(featureName, subFeatureName) {
  const featuresListString = localStorage.getItem('features');

  if (!featuresListString) {
    return null;
  }

  const featuresList = JSON.parse(featuresListString);

  const singleFeature = featuresList?.find((feature) => feature.name === featureName);

  if (singleFeature) {
    const singleSubFeature = singleFeature?.sub_features
      ?.find((subFeature) => subFeature.sub_feature_name === subFeatureName);

    if (singleSubFeature) {
      return singleSubFeature.value;
    }
  }
  return null;
}

export function secondsToHms(d) {
  const s = Number(d);
  const m = Math.floor((s % 3600) / 60);
  return m > 0 ? `${m} minutes ago` : `${s} seconds ago`;
}

export function convertNumberToMonth(number) {
  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
  ];

  let value;
  if (number >= 1 && number <= 12) {
    value = months[number - 1];
  } else {
    value = null;
  }

  return value;
}

export function statusColor(value) {
  let color;
  let backgroundColor;
  let statusToShow;
  switch (value) {
    case 'inProgress':
      statusToShow = 'On-going';
      color = '#F37D10';
      backgroundColor = '#FEFBF7';
      break;
    case 'upcoming':
      statusToShow = 'Upcoming';
      color = '#1C75BC';
      backgroundColor = '#F7FBFE';
      break;
    case 'completed':
      statusToShow = 'Completed';
      color = '#198431';
      backgroundColor = '#F7FEF8';
      break;
    case 'cancelled':
      statusToShow = 'Cancelled';
      color = '#E8212E';
      backgroundColor = '#FCF7F7';
      break;
    case 'Active':
      color = '#198437';
      backgroundColor = '#F7FAFC';
      break;
    case 'Inactive':
      color = '#E8212e';
      backgroundColor = '#ffd7d973';
      break;
    default:
      break;
  }
  return { color, backgroundColor, statusToShow };
}

export function mimString(value) {
  return value ? `${value.toString().replace(/,/g, ', ').substring(0, 50)}${value.toString().length > 49 ? '...' : ''}` : '';
}

// To add currency symbol to the value
export function currencySymbol(currency, data = '') {
  const currencies = [
    {
      currency: 'SAR',
      symbol: '﷼',
    },
    {
      currency: 'USD',
      symbol: '$',
    },
    {
      currency: 'EUR',
      symbol: '€',
    },
    {
      currency: 'INR',
      symbol: '₹',
    },
  ];

  const symbolToAdd = currencies?.find((each) => each.currency === currency)?.symbol || '$';
  return `${symbolToAdd} ${data}`;
}

export function getTimeDifference(utcDateString) {
  const utcDate = new Date(utcDateString);
  const currentDate = new Date();

  // Calculate the difference in milliseconds
  const differenceMs = currentDate - utcDate;

  // Convert milliseconds to seconds, minutes, hours, days, months, years
  const seconds = Math.floor(differenceMs / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30); // Approximation
  const years = Math.floor(months / 12);

  // Choose appropriate unit based on the magnitude of difference
  let difference;
  if (years > 0) {
    difference = years + (years === 1 ? ' year ago' : ' years ago');
  } else if (months > 0) {
    difference = months + (months === 1 ? ' month ago' : ' months ago');
  } else if (days > 0) {
    difference = days + (days === 1 ? ' day ago' : ' days ago');
  } else if (hours > 0) {
    difference = hours + (hours === 1 ? ' hour ago' : ' hours ago');
  } else if (minutes > 0) {
    difference = minutes + (minutes === 1 ? ' minute ago' : ' minutes ago');
  } else {
    difference = 'just now';
  }
  return difference;
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export async function fetchAndConvert(url) {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
}

export function fetchColor(url) {
  const colors = {
    active: '#5B93FF',
    inactive: '#FF8F6B',
    android: '#FFD66B',
    webOs: '#FF8F6B',
    windows: '#5B93FF',
    tizen: '#2DCE9C',
    SyncPercentage: '#e8e8e8',
    NonSyncPercentage: '#cecece',
  };
  return colors[url] || getRandomColor();
}
export function getResolutionCategory(screenWidth, screenHeight) {
  let resolution = '';
  const screenWidthInNumber = parseFloat(screenWidth);
  const screenHeightInNumber = parseFloat(screenHeight);

  if (screenWidthInNumber >= 7680) {
    resolution = '8K';
  } else if (screenWidthInNumber >= 3840) {
    resolution = '4K';
  } else if (screenWidthInNumber >= 2560) {
    resolution = 'UHD';
  } else if (screenWidthInNumber >= 1920) {
    resolution = 'FHD';
  } else if (screenWidthInNumber >= 1280) {
    resolution = 'HD';
  } else {
    resolution = '';
  }

  return `${resolution} ${Math.floor(screenWidthInNumber)} PX * ${Math.floor(screenHeightInNumber)} PX`;
}

export function noDays(numbers, fun) {
  return numbers > 1 ? `${numbers} ${fun('days')}` : fun('day');
}

export function convertSecondsToHoursAndMinutes(seconds) {
  const minutes = Math.floor(seconds / 60);
  if (minutes < 60) {
    return `${minutes}m`;
  }

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return `${hours}h ${remainingMinutes}m`;
}

export function addImageType(type) {
  const split = type?.split('/');
  let output;
  if (type === 'application/pdf') {
    output = 'PDF';
  } else if (type?.includes('template')) {
    output = 'Template';
  } else {
    output = split?.[0];
  }
  return <div className="icon-text">{output}</div>;
}

export function statusFileType(type, isPosContent) {
  const split = type.split('/');
  let output;
  if (type === 'application/pdf') {
    output = 'PDF';
  } else if (isPosContent) {
    output = 'PosItEasy';
  } else {
    output = split?.[0];
  }
  return output;
}

export function timeFormat(data) {
  const hour = data?.$H;
  const minute = data?.$m;
  const formattedTime = `${hour < 10 ? '0' : ''}${hour}:${minute < 10 ? '0' : ''}${minute}`;
  return formattedTime;
}

export function convertToOffset(offset) {
  // Parse the input date string into a Date object
  const date = new Date();
  const nowUtc = new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );
  const getTime = nowUtc.getTime() + (offset * 1000);
  return new Date(getTime);
}

export function weekDay(count) {
  const array = [
    'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday',
  ];
  return array[count];
}

export function fileTypeValidation(file, type) {
  const getFileTypes = (fileType) => {
    switch (fileType) {
      case 'image':
        return {
          fileTypes: 'jpg|jpeg|png|gif',
          errorMessage: 'Invalid file format. Please upload a PNG, GIF, or JPEG image.',
        };
      case 'video':
        return {
          fileTypes: 'mp4',
          errorMessage: 'Invalid file format. Please upload a MP4 video.',
        };
      case 'application/pdf':
        return {
          fileTypes: 'pdf',
          errorMessage: 'Invalid file format. Please upload a PDF file.',
        };
      default:
        return {
          fileTypes: '',
          errorMessage: 'Invalid file format. Please upload a valid file',
        };
    }
  };
  const fileTypesValidation = getFileTypes(type);
  const pattern = new RegExp(`\\.(${fileTypesValidation.fileTypes})$`, 'i');
  let message;
  if (!pattern.test(file.name.toLowerCase())) {
    message = fileTypesValidation.errorMessage;
  } else {
    message = '';
  }
  return message;
}

function loadSingleImage(file) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const url = URL.createObjectURL(file); // Convert binary file to a URL
    img.src = url;

    img.onload = () => {
      URL.revokeObjectURL(url); // Clean up the URL object once the image is loaded
      resolve(img);
    };
    img.onerror = (err) => {
      URL.revokeObjectURL(url); // Clean up the URL object in case of an error
      console.error('Error loading image:', err);
      reject(new Error('Failed to load image'));
    };
  });
}

async function dimensionCheck(file, width = 50, height = 50) {
  if (!file?.type?.includes('image')) {
    // If it's not an image, return true by default
    return true;
  }
  return loadSingleImage(file)
    .then((img) => {
      const tempWidth = img.width;
      const tempHeight = img.height;

      return tempWidth >= width && tempHeight >= height;
    })
    .catch((error) => {
      console.error('Error loading image:', error);
      return false;
    });
}

export async function fileSizeDimensionValidation(file, maxFileSizeInMB, checkDimension = false) {
  let validation = '';
  const maxFileSizeInBytes = maxFileSizeInMB * 1024 * 1024; // Convert MB to bytes
  if (checkDimension) {
    const dimensionCheckData = await dimensionCheck(file, 50, 50);
    if (!dimensionCheckData) {
      validation = 'Dimension should be minimum of 50 x 50';
    }
  }
  if (file?.size > maxFileSizeInBytes) {
    validation = `File size should be less than ${maxFileSizeInMB} MB`;
  }
  return validation;
}

export async function contentUploadValidation(file, maxFileSizeInMB, type, contentUpload = false) {
  const maxFileSizeInBytes = maxFileSizeInMB * 1024 * 1024; // Convert MB to bytes
  let message;
  const fileTypeCheck = fileTypeValidation(file, type);

  let dimensionCheckData = true;
  if (contentUpload) dimensionCheckData = await dimensionCheck(file, 50, 50);

  if (fileTypeCheck !== '') {
    message = fileTypeCheck;
  } else if (file?.size > maxFileSizeInBytes) {
    message = `File size should be less than ${maxFileSizeInMB} MB`;
  } else if (contentUpload && !dimensionCheckData) {
    message = 'File resolution should be minimum of 50 x 50';
  } else {
    message = '';
  }
  return message;
}

export function fileUploadValidation(file, maxFileSizeInMB, type) {
  const maxFileSizeInBytes = maxFileSizeInMB * 1024 * 1024; // Convert MB to bytes
  let message;
  const fileTypeCheck = fileTypeValidation(file, type);
  if (fileTypeCheck !== '') {
    message = fileTypeCheck;
  } else if (file?.size > maxFileSizeInBytes) {
    message = `File size should be less than ${maxFileSizeInMB} MB`;
  } else {
    message = '';
  }
  return message;
}

export function useRecaptcha(action) {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const VerifyRecaptcha = async () => {
    const recaptchaToken = await executeRecaptcha(action);
    return recaptchaToken;
  };
  return { VerifyRecaptcha };
}

export function dataURLtoFile(dataUrl, filename) {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n > 0) {
    n -= 1;
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export function uploadImageResize(file) {
  const imageUrls = {};
  const img = document.createElement('img');
  img.onload = () => {
    const resizeImage = (targetWidth, targetHeight) => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = targetWidth;
      canvas.height = targetHeight;
      ctx.drawImage(img, 0, 0, targetWidth, targetHeight);
      return canvas.toDataURL('image/jpeg', 1.0); // Use quality of 1.0 for full quality
    };

    const width = img?.width;
    const height = img?.height;

    // 1. Resize to 8K
    if (width > 7680 && height > 4320) {
      imageUrls.image1 = resizeImage(7680, 4320);
      imageUrls.image2 = resizeImage(600, 400);
      imageUrls.image3 = resizeImage(50, 50);
    } else if ((width > 600 && width <= 7680) || (height > 400 && height <= 4320)) {
      imageUrls.image1 = resizeImage(width, height);
      imageUrls.image2 = resizeImage(600, 400);
      imageUrls.image3 = resizeImage(50, 50);
    } else if ((width > 25 && width <= 600) || (height > 25 && height <= 400)) {
      imageUrls.image1 = resizeImage(width, height);
      imageUrls.image2 = resizeImage(width, height);
      imageUrls.image3 = resizeImage(50, 50);
    } else {
      imageUrls.image1 = resizeImage(25, 25);
      imageUrls.image2 = resizeImage(25, 25);
      imageUrls.image3 = resizeImage(25, 25);
    }
  };
  img.src = file;
  return imageUrls;
}

export function uploadImageResizeSingle(file) {
  return new Promise((resolve) => {
    const img = document.createElement('img');

    img.onload = () => {
      const resizeImage = (targetWidth, targetHeight) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = targetWidth;
        canvas.height = targetHeight;
        ctx.drawImage(img, 0, 0, targetWidth, targetHeight);
        return canvas.toDataURL('image/jpeg');
      };

      const width = img?.width;
      const height = img?.height;

      // Resize to 8K if the original is above 8K
      const resizedImage = (width > 7680 && height > 4320)
        ? resizeImage(7680, 4320)
        : URL.createObjectURL(file); // Use the original file URL

      // If resizing was done, convert to binary format
      if (width > 7680 && height > 4320) {
        resolve(dataURLtoFile(resizedImage, 'resized_image'));
      } else {
        resolve(file); // Return original file
      }
    };

    img.src = URL.createObjectURL(file);
  });
}

export function getDaysBetweenDates(targetDate, endDate) {
  const parsedTargetDate = new Date(targetDate);
  const parsedEndDate = new Date(endDate);
  const differenceInTime = Math.abs(parsedTargetDate.getTime() - parsedEndDate.getTime());
  const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

  return differenceInDays;
}
export async function contentUploadImageResize(file) {
  const imageUrls = {};

  // A function that returns a promise which resolves when the image is loaded
  const loadImage = (src) => new Promise((resolve, reject) => {
    const img = document.createElement('img');
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });

  // Wait for the image to load
  const img = await loadImage(URL.createObjectURL(file));

  const resizeImage = (targetWidth, targetHeight) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = targetWidth;
    canvas.height = targetHeight;
    ctx.drawImage(img, 0, 0, targetWidth, targetHeight);
    return canvas.toDataURL(file.type, 1.0); // Use quality of 1.0 for full quality
  };

  const width = img?.width;
  const height = img?.height;

  const convertImageResolution = (checkWidth, actualWidth, checkHeight, actualHeight) => {
    let tempWidth = actualWidth;
    let tempHeight = actualHeight;
    if (actualWidth >= checkWidth || actualHeight >= checkHeight) {
      if (actualWidth >= checkWidth) {
        tempWidth = checkWidth;
      }

      if (actualHeight >= checkHeight) {
        tempHeight = checkHeight;
      }

      const resizedImageData = {
        resizedImageUrl: resizeImage(tempWidth, tempHeight),
        actual_width_in_resolution: tempWidth,
        actual_height_in_resolution: tempHeight,
      };

      return resizedImageData;
    }
    return undefined;
  };

  // imageUrls.px_7680x4320 = convertImageResolution(7680, width, 4320, height);
  // imageUrls.px_3840x2160 = convertImageResolution(3840, width, 2160, height);
  // imageUrls.px_2048x1080 = convertImageResolution(2048, width, 1080, height);
  // imageUrls.px_1920x1080 = convertImageResolution(1920, width, 1080, height);
  // imageUrls.px_1280x720 = convertImageResolution(1280, width, 720, height);
  imageUrls.px_600x400 = convertImageResolution(600, width, 400, height);
  imageUrls.px_50x50 = convertImageResolution(50, width, 50, height);

  const resolutionContainingImages = Object.fromEntries(
    Object.entries(imageUrls).filter(([, value]) => value !== undefined),
  );

  return resolutionContainingImages;
}

// Remove the extension
export function fileNameWithoutExtension(fileName) {
  return fileName.substring(0, fileName.lastIndexOf('.'));
}

// Intl DateTime format
export function intlDateTimeFormat(dateStr) {
  const date = new Date(dateStr);
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  })?.format(date);

  return formattedDate; // output like Dec 1, 2024
}

export function changeSubscriptionTenure(tenure) {
  let result = '';
  if (tenure === 1) {
    result = 'Monthly';
  } else if (tenure === 6) {
    result = 'Semi Annual';
  } else if (tenure === 12) {
    result = 'Annual';
  }
  return result;
}

function getMediaDimensions(originalMediaElement, type) {
  return new Promise((resolve, reject) => {
    // Clone the media element to avoid modifying the original function parameter
    const mediaElement = originalMediaElement.cloneNode();

    if (type === 'video') {
      mediaElement.addEventListener('loadedmetadata', () => {
        if (mediaElement.videoWidth && mediaElement.videoHeight) {
          resolve({ width: mediaElement.videoWidth, height: mediaElement.videoHeight });
        } else {
          reject(new Error('Failed to get video dimensions'));
        }
      });

      mediaElement.onerror = () => reject(new Error('Error loading video metadata'));
    } else if (type === 'image') {
      mediaElement.onload = () => {
        resolve({ width: mediaElement.naturalWidth, height: mediaElement.naturalHeight });
      };

      mediaElement.onerror = () => reject(new Error('Failed to load image and extract dimensions'));
    } else {
      reject(new Error('Unsupported media type'));
    }
  });
}

export async function extractMediaDimensions(binaryData, type) {
  let mediaElement;

  if (type === 'video') {
    mediaElement = document.createElement('video');
  } else if (type === 'image') {
    mediaElement = document.createElement('img');
  } else {
    throw new Error('Unsupported media type');
  }

  // Create a new URL for the binary data without modifying the function parameter
  const objectUrl = URL.createObjectURL(new Blob([binaryData]));
  mediaElement.src = objectUrl;

  try {
    const dimensions = await getMediaDimensions(mediaElement, type);
    return dimensions;
  } catch (error) {
    console.error('Error extracting media dimensions:', error);
    return null;
  } finally {
    URL.revokeObjectURL(objectUrl);
  }
}

export function convertAgeRange(input) {
  const ageIntervals = [
    [0, 10],
    [10, 15],
    [15, 25],
    [25, 35],
    [35, 50],
    [50, 70],
  ];

  const result = [];

  // Helper function to find the appropriate segment for an age range
  const getAgeRangeSegments = (ageRange) => {
    const [startAge, endAge] = ageRange.split('-').map(Number);
    const segments = [];

    // Loop through the predefined intervals
    ageIntervals.forEach(([min, max]) => {
      if (endAge <= min || startAge >= max) {
        // Skip this segment if no overlap
        return;
      }
      // Create the segment
      const segmentStart = Math.max(startAge, min);
      const segmentEnd = Math.min(endAge, max);
      segments.push(`${segmentStart}-${segmentEnd}`);
    });

    return segments;
  };

  // Iterate over each gender group (Male and Female)
  if (input?.Male?.length || input?.Female?.length) {
    Object.entries(input).forEach(([, entries]) => {
      entries.forEach((entry) => {
        const [genderType, ageRange] = entry.split(' : ');
        const segments = getAgeRangeSegments(ageRange);

        // Push the formatted result
        segments.forEach((segment) => {
          result.push(`${genderType}(${segment})`);
        });
      });
    });
  }
  return result;
}

export function reConvertAgeRanges(inputArray) {
  // Initialize the result object to store the converted data
  const result = {
    Male: [],
    Female: [],
  };
  // Define the mapping for the age ranges
  const ageGroups = {
    '0-10': '0-10',
    '10-15': '10-15',
    '15-25': '15-25',
    '25-35': '25-35',
    '35-50': '35-50',
    '50-70': '50-70',
  };
  // Iterate over the input array and process each item
  inputArray.forEach((item) => {
    // Split the item to get gender and age range
    const [gender, ageRange] = item.split('(');
    const cleanAgeRange = ageRange.replace(')', ''); // Remove the closing parenthesis
    // Check if the age range exists in our defined groups
    if (ageGroups[cleanAgeRange]) {
      // Construct the formatted string: "male : 0-10"
      const category = `${gender} : ${ageGroups[cleanAgeRange]}`;
      // Add the category to the appropriate gender's list
      result[gender].push(category);
    }
  });
  return result;
}

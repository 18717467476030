import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DynamicSplitPane from './DynamicSplitPane';
import { BackIcon } from '../../../shared/component/svgIcon';
import customLayouts from '../../../utils/api/content-layout';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import { addNewLayout } from '../../../redux/slices/ContentLayoutSlice';

function CustomLayout() {
  const { t } = useTranslation();
  const deviceTypes = [
    { name: 'landscape', width: 1920, height: 1080 },
    { name: 'portrait', width: 1080, height: 1920 },
    { name: 'Bar Display', width: 1920, height: 158 },
    { name: 'Shelf Display', width: 1920, height: 360 },
  ];
  const deviceType = useSelector((state) => state?.contentLayout?.deviceType);
  const [width] = useState(deviceTypes?.[deviceType]?.width);
  const [height] = useState(deviceTypes?.[deviceType]?.height);
  const [create, setCreate] = useState(false);
  const [layoutData, setLayoutData] = useState(null);
  const [partitionName, setPartitionName] = useState('');
  const [reset, setReset] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleBack = () => {
    navigate('/admin/content-layout');
  };

  const generateGridLayout = (nodeArray, rowStarts = 1, colStarts = 1, totalRow = 12, totalColumn = 12) => {
    const layouts = [];
    let count = 1; // For unique partition_id values (P1, P2, etc.)
    const traverseNodes = (nodes, rowStart, colStart, totalRows, totalColumns) => {
      nodes.forEach((node) => {
        if (node?.children && node?.children?.length > 0 && node?.flex?.length > 1) {
          const isVertical = node?.type === 'vertical';
          const totalFlex = node?.flex.reduce((sum, val) => sum + val, 0);
          let currentRowStart = rowStart;
          let currentColStart = colStart;
          node?.children.forEach((child, childIndex) => {
            const childFlex = node?.flex?.[childIndex];
            const partitionSpan = Math.round((childFlex / totalFlex) * (isVertical ? totalColumns : totalRows));
            const layout = {
              grid_row_start: isVertical ? currentColStart : colStart,
              grid_row_end: isVertical ? currentColStart + partitionSpan : colStart + totalColumns,
              grid_column_start: isVertical ? rowStart : currentRowStart,
              grid_column_end: isVertical ? rowStart + totalRows : currentRowStart + partitionSpan,
              partition_id: `P${count}`,
            };
            // Push layout for each partition and increment count
            if (!child?.flex?.length || child?.flex?.length > 1) {
              layouts.push(layout);
              count += 1;
            }
            // Update the start position for the next child
            if (isVertical) {
              currentColStart += partitionSpan;
            } else {
              currentRowStart += partitionSpan;
            }
            // Recursively handle nested children if they ex
            if (child?.children && child?.children?.length > 0) {
              traverseNodes(
                child.children,
                layout.grid_column_start,
                layout.grid_row_start,
                layout.grid_column_end - layout.grid_column_start,
                layout.grid_row_end - layout.grid_row_start,
              );
            }
          });
        }
      });
    };
    // Start traversing from the top-level nodes
    traverseNodes(nodeArray, rowStarts, colStarts, totalRow, totalColumn);
    return layouts;
  };

  const handleCreateLayout = () => {
    if (layoutData?.length) {
      if (!partitionName?.length) {
        dispatch(setErrorNotification('Partition Name should not be empty!'));
      }
      const generatedLayout = generateGridLayout(layoutData);
      const requestObject = {
        layouts: generatedLayout,
        generated_layout: layoutData,
        name: partitionName,
        orientation: deviceTypes?.[deviceType]?.name,
      };
      customLayouts?.createCustomLayout(requestObject).then((res) => {
        if (res?.data) {
          const updatedLayouts = res.data.layouts.map((layout) => ({
            ...layout,
            file_types: [], // Adding the new field here
          }));
          // Create the new layout data object with the updated layouts array
          const newLayoutData = {
            ...res.data,
            layouts: updatedLayouts,
          };
          dispatch(addNewLayout(newLayoutData));
          navigate('/admin/content-layout');
        }
      }).catch((err) => {
        dispatch(setErrorNotification(err?.response?.data));
      });
    }
  };

  const handleResetLayout = () => {
    setReset(true);
    setCreate(false);
  };

  return (
    <>
      <div className="custom-layout-header">
        <div className="layout-left-wrap">
          <div role="none" onClick={handleBack}>
            <BackIcon />
          </div>
          <div className="dimension-inputs">
            <label htmlFor="width-input">
              W
              <input
                id="width-input"
                type="number"
                value={width}
                className="dimension-input"
              />
            </label>
            <span className="dimension-multiplier">x</span>
            <label htmlFor="height-input">
              H
              <input
                id="height-input"
                type="number"
                value={height}
                className="dimension-input"
              />
            </label>
          </div>
        </div>
        <div className="name-container">
          <input
            className="name-input"
            type="text"
            value={partitionName}
            onChange={(e) => setPartitionName(e.target.value)}
            placeholder="Enter name"
          />
        </div>
        <button
          type="button"
          className={!create ? 'create-layout-button' : 'create-layout-button-active'}
          onClick={handleResetLayout}
        >
          {t('reset')}
        </button>
        <button
          type="button"
          className={!create ? 'create-layout-button' : 'create-layout-button-active'}
          onClick={handleCreateLayout}
        >
          {t('createLayout')}
        </button>
      </div>
      <DynamicSplitPane setLayoutData={setLayoutData} setCreate={setCreate} reset={reset} setReset={setReset} />
    </>
  );
}

export default CustomLayout;

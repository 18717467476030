import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Input } from '../../../shared/component/form/Input';
import Button from '../../../shared/component/Button';
import { useRecaptcha } from '../../../utils/helpers';
import auth from '../../../utils/api/auth';
import { emailValidation } from '../../../shared/component/form/Validation';

function EmailValidation(props) {
  const { t } = useTranslation();
  const [selfRegisterOtp, setSelfRegisterOtp] = useState(false);
  const { VerifyRecaptcha } = useRecaptcha();
  const [otpButtonDisable, setOtpButtonDisable] = useState(true);
  const [otpVerifying, setOtpVerifying] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [value, setValue] = useState();
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
  const [showResend, setShowResend] = useState(true);
  const [resendTimer, setResendTimer] = useState(0);
  const [timerActive, setTimerActive] = useState(false);

  const startResendTimer = () => {
    setTimerActive(true);
    setResendTimer(60);
    const interval = setInterval(() => {
      setResendTimer((prev) => {
        if (prev <= 1) {
          clearInterval(interval);
          setTimerActive(false);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };
  const generateOtp = async () => {
    setSubmitButtonClicked(true);
    if (emailValidation(props?.formData?.email)) {
      try {
        const recaptchaToken = await VerifyRecaptcha('register');
        const payloadValue = { ...value, recaptchaToken };
        setOtpButtonDisable(true);
        setOtpVerifying(true);
        auth.sendVerificationEmail(payloadValue)
          .then(() => {
            setOtpVerifying(false);
            props?.setSubtitle(t('enterOtpEmail'));
            setSelfRegisterOtp(true);
            startResendTimer();
          })
          .catch((err) => {
            setOtpVerifying(false);
            if (err?.response?.data?.message === 'Email already exists') {
              props?.setSubtitle(t('enterOtpEmail'));
              setSelfRegisterOtp(true);
              startResendTimer();
            } else {
              setSelfRegisterOtp(false);
            }
            setErrorMsg(err?.response?.data?.message);
          });
      } catch (err) {
        setSelfRegisterOtp(false);
        setErrorMsg(err);
      }
    }
  };
  const handleResend = async () => {
    if (emailValidation(props?.formData?.email) && resendTimer === 0) {
      try {
        const recaptchaToken = await VerifyRecaptcha('resendOtp');
        const payload = {
          email: value?.email,
        };
        const payloadValue = { ...payload, recaptchaToken };
        setSelfRegisterOtp(false);
        setShowResend(true);
        setOtpButtonDisable(true);
        setOtpVerifying(true);
        auth.sendVerificationEmail(payloadValue)
          .then(() => {
            setSelfRegisterOtp(true);
            setResendTimer(true);
            setErrorMsg('');
            setOtpVerifying(false);
            startResendTimer();
          })
          .catch((err) => {
            setOtpVerifying(false);
            setErrorMsg(err?.response?.data?.message);
          });
      } catch (err) {
        setErrorMsg(err);
      }
    }
  };

  const validateOtp = async () => {
    setOtpVerifying(true);
    const recaptchaToken = await VerifyRecaptcha('verifyOtp');
    const requestObject = {
      ...value, recaptchaToken,
    };
    await auth.verifyEmail(requestObject)
      .then((res) => {
        const registerToken = res?.data?.token;
        const formObject = {
          ...value,
          registerToken,
        };
        props?.setFormData(formObject);
        setOtpVerifying(false);
        props?.setSubtitle('');
        props?.setFormStage(2);
      })
      .catch((err) => {
        setOtpVerifying(false);
        setErrorMsg(err?.response?.data?.message);
        setShowResend(true);
      });
  };

  const handleChange = (name, changeValue) => {
    if (name === 'email') {
      props?.setFormData({ ...props?.formData, [name]: changeValue });
      setValue({ ...value, [name]: changeValue });
    } else {
      setValue({ ...value, selfRegisterOtp: changeValue });
    }
  };

  useEffect(() => {
    setErrorMsg('');
    if ((value?.email && !selfRegisterOtp) || value?.selfRegisterOtp?.length === 6) {
      setOtpButtonDisable(false);
    } else {
      setOtpButtonDisable(true);
    }
  }, [value, selfRegisterOtp]);
  return (
    <div className="validation-wrap">
      <Input
        name="email"
        value={props?.formData?.email}
        change={handleChange}
        placeholder={t('enterWorkEmail')}
        error={!emailValidation(props?.formData?.email) && submitButtonClicked}
        errorMessage={t('invalidEmail')}
      />
      {selfRegisterOtp && (
        <div>
          <Input
            type="text"
            name="otp"
            value={value?.selfRegisterOtp}
            change={handleChange}
            placeholder={t('enterOtp')}
            maxLength={6}
          />
        </div>
      )}

      <div className={errorMsg ? 'otp-error' : 'otp-resend'}>
        {errorMsg && <p className="errorMsg">{errorMsg}</p>}
        {showResend && selfRegisterOtp && (
          <span className="resend-otp" onClick={handleResend} role="none">
            {!timerActive ? t('resendOTP') : `${t('resendAvailableIn')} ${resendTimer} ${t('seconds')}`}
          </span>
        )}
      </div>
      <Button
        label={selfRegisterOtp && showResend ? t('submit') : t('sendOtp')}
        click={selfRegisterOtp ? validateOtp : generateOtp}
        classes={otpVerifying ? 'success-button loading-btn' : 'success-button'}
        disabled={otpButtonDisable}
        loading={otpVerifying}
      />
      <div className="have-an-account">
        <p>{t('alreadyHaveAnAccount')}</p>
        <Link to="../login">
          {t('login')}
        </Link>
      </div>
    </div>
  );
}

EmailValidation.propTypes = {
  formData: PropTypes.shape({
    email: PropTypes.string.isRequired,
  }).isRequired,
  setFormData: PropTypes.func.isRequired,
  setSubtitle: PropTypes.func.isRequired,
  setFormStage: PropTypes.func.isRequired,
};

export default EmailValidation;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Country, State, City } from 'country-state-city';
import AutocompleteBox from '../../../shared/component/form/AutoComplete';
import TopContent from '../../../shared/component/TopContent';
import { Input, TextArea, InputRightInnerIcon } from '../../../shared/component/form/Input';
import Button from '../../../shared/component/Button';

import timeZonesList from '../../../assets/json/time-zone.json';
import SelectBox from '../../../shared/component/form/SelectBox';

import location from '../../../utils/api/store-location';

import locationIcon from '../../../assets/icons/location.png';

import {
  fetchStoreLocationListAll,
  fetchCityStateCountry,
  createNewStore,
  createNewStoreLocally,
} from '../../../redux/slices/StoreLocationSlice';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import ModalBox from '../../../shared/component/ModalBox';
import Success from '../../../shared/component/Success';
import Location from './Location';
import AddSingleFloorMap from './AddSingleFloorMap';
import DropDownMenu from '../../../shared/component/DropDownMenu';
import ViewFloorMap from './ViewFloorMap';
import ImageUploadBox from './ImageUploadBox';
import { CloseCircleIcon, MoreMenuIcon } from '../../../shared/component/svgIcon';
import ViewStoreImage from './ViewStoreImage';
import {
  decimalValidation,
  descriptionValidation,
  nameValidation,
  pincodeValidation,
  textNumberValidation,
} from '../../../shared/component/form/Validation';
import { dataURLtoFile, uploadImageResize } from '../../../utils/helpers';

function LocationAdd() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { singleStoreData } = useSelector((state) => state.store);

  const [storeValue, setStoreValue] = useState(singleStoreData || {
    floorMapFiles: [],
    storeImages: [],
  });
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [countries, setCountries] = useState([]);
  const [timeZone, setTimeZone] = useState([]);
  const [disableButton, setDisableButton] = useState(true);
  const [countryCode, setCountryCode] = useState('');

  // For Notification
  const [uploading, setUploading] = useState(false);
  const [notification, setNotification] = useState(false);
  const [notificationStatus, setNotificationStatus] = useState(true);
  const [modelContent, setModelContent] = useState(true);
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
  const [resizeImages, setResizeImages] = useState();

  const addLocation = () => {
    navigate('../');
  };

  const submitFloorMap = (floorMap) => {
    setStoreValue((prevValue) => ({
      ...prevValue,
      floorMapFiles: [
        ...prevValue.floorMapFiles || [],
        floorMap,
      ],
    }));
  };

  const removeFloorMap = (id) => {
    setStoreValue((prevValue) => ({
      ...prevValue,
      floorMapFiles: prevValue.floorMapFiles.filter(
        (floorMap, index) => index !== id,
      ),
    }));
  };

  const viewFloorMap = (id) => {
    const floorMapValue = storeValue.floorMapFiles.find(
      (floorMap, index) => index === id,
    );
    setNotificationStatus(false);
    setNotification(true);
    setModelContent({
      title: floorMapValue?.name,
      content: (
        <ViewFloorMap
          oldFloorMapValue={floorMapValue}
        />
      ),
    });
  };

  const addDevicesInFloor = (index) => {
    navigate(`./floor-map-add/${index}`);
  };

  const actions = [
    {
      name: t('view'),
      url: '',
      function: viewFloorMap,
      key: 'view',
    },
    {
      name: t('markDevice'),
      url: '',
      function: addDevicesInFloor,
      key: 'view',
    },
    {
      name: t('download'),
      url: '',
      function: (index) => {
        const link = document.createElement('a');
        link.href = storeValue?.floorMapFiles[index].viewImage;
        link.setAttribute(
          'download',
          storeValue?.floorMapFiles[index].fileName,
        );
        document.body.appendChild(link);
        link.click();
      },
      key: 'view',
    },
    {
      name: t('delete'),
      url: '',
      function: removeFloorMap,
      key: 'delete',
    },
  ];

  const submit = () => {
    setSubmitButtonClicked(true);
    if (nameValidation(1, 50, storeValue?.name)
      && descriptionValidation(500, storeValue?.description)
      && descriptionValidation(100, storeValue?.area)
      && textNumberValidation(1, 30, storeValue?.store_id)
      && pincodeValidation(storeValue?.pincode)
      && decimalValidation(-90, 90, storeValue?.latitude)
      && decimalValidation(-180, 180, storeValue?.longitude)
      && descriptionValidation(50, storeValue?.zone)
    ) {
      setDisableButton(true);
      setUploading(true);
      Object.keys(storeValue).forEach(
        (key) => (storeValue[key] === '') && delete storeValue[key],
      );
      // Convert object into formData
      const formData = new FormData();

      // Append image files
      if (storeValue?.storeImages?.length > 0) {
        storeValue?.storeImages?.forEach((imageFile) => {
          formData.append('store', imageFile.file);
        });
        if (resizeImages) {
          formData.append('thumbnail[px_600x400]', dataURLtoFile(resizeImages?.image2, storeValue?.name));
          formData.append('thumbnail[px_50x50]', dataURLtoFile(resizeImages?.image3, storeValue?.name));
        }
        const thumbnail = storeValue?.storeImages?.find((image) => image.setDefaultThumb === true)?.file;
        if (thumbnail) formData.append('thumbnail', thumbnail);
      }

      if (storeValue?.description) {
        formData.append('description', storeValue?.description);
      }

      if (storeValue?.zone) {
        formData.append('zone', storeValue?.zone);
      }

      // Append other fields
      formData.append('name', storeValue?.name);
      formData.append('store_id', storeValue?.store_id);
      formData.append('country', storeValue?.country);
      formData.append('state', storeValue?.state);
      formData.append('city', storeValue?.city);
      formData.append('area', storeValue?.area);
      formData.append('address', storeValue?.area);
      formData.append('pincode', storeValue?.pincode);
      formData.append('time_zone', storeValue?.time_zone);
      formData.append('latitude', storeValue?.latitude);
      formData.append('longitude', storeValue?.longitude);

      // Append floor map marked device
      if (storeValue?.floorMapFiles?.length > 0) {
        storeValue?.floorMapFiles.forEach((floorMap, index) => {
          formData.append(`floorMaps_data[${index}][name]`, floorMap.name);

          // Append tags as array
          if (floorMap?.tags?.length > 0) {
            floorMap.tags.forEach((tag, tagIndex) => {
              formData.append(`floorMaps_data[${index}][tags][${tagIndex}]`, tag);
            });
          }

          if (floorMap?.layout?.length > 0) {
            floorMap.layout.forEach((layoutItem, itemIndex) => {
              formData.append(`floorMaps_data[${index}][layout][${itemIndex}][device_id]`, layoutItem.id);
              formData.append(`floorMaps_data[${index}][layout][${itemIndex}][x]`, layoutItem.top);
              formData.append(`floorMaps_data[${index}][layout][${itemIndex}][y]`, layoutItem.left);
            });
          }
        });

        storeValue?.floorMapFiles.forEach((floorMap) => {
          formData.append('floor', floorMap.file);
        });
      }
      location.addLocation(formData).then((res) => {
        if (Object.keys(res?.data)?.length > 0) {
          dispatch(createNewStore(res?.data));
        }
        setUploading(false);
        setNotification(true);
        setNotificationStatus(true);
        setModelContent({ content: <Success message={t('facilityCreatedSuccess')} /> });
        setTimeout(() => {
          setNotification(false);
          navigate('../');
          dispatch(fetchStoreLocationListAll());
          dispatch(fetchCityStateCountry());
          setDisableButton(false);
          dispatch(createNewStoreLocally({}));
        }, 3000);
      }).catch((error) => {
        setUploading(false);
        dispatch(setErrorNotification(error?.response?.data));
        setDisableButton(false);
      });
    }
  };

  const removeImageFile = (id) => {
    const updatedImages = storeValue.storeImages.filter((image, index) => index !== id);
    setStoreValue((prevValue) => ({
      ...prevValue,
      storeImages: updatedImages,
    }));
  };

  const setThumbnail = (key) => {
    setStoreValue((prevValue) => ({
      ...prevValue,
      storeImages: prevValue.storeImages.map((image, index) => {
        if (index === key) {
          return {
            ...image,
            setDefaultThumb: true,
          };
        }
        return {
          ...image,
          setDefaultThumb: false,
        };
      }),
    }));
    setNotification(false);
  };

  const closeModal = () => {
    setNotification(false);
  };

  const viewImage = (key) => {
    setNotificationStatus(true);
    setNotification(true);
    setModelContent({
      title: '',
      content: (
        <ViewStoreImage
          storeImage={storeValue.storeImages[key]}
          setThumbnail={setThumbnail}
          indexKey={key}
          closeModal={closeModal}
        />
      ),
    });
  };

  const changeHandle = (name, changeValue) => {
    if (name === 'country_id') {
      if (changeValue) {
        setStoreValue((prevValue) => ({
          ...prevValue,
          country: changeValue.label,
          state: '',
          city: '',
        }));
        setCountryCode(changeValue.id);
        const state1 = State.getStatesOfCountry(changeValue.id).map((val) => ({ id: val.isoCode, label: val.name }));
        setState(state1);
      }
    } else if (name === 'files') {
      let selectedFiles = [];
      for (let index = 0; index < changeValue.length; index += 1) {
        selectedFiles = [
          ...selectedFiles,
          {
            file: changeValue[index],
            viewUrl: URL.createObjectURL(changeValue[index]),
            setDefaultThumb: false,
          },
        ];
      }
      const imageResize = uploadImageResize(URL.createObjectURL(changeValue[0]));
      setResizeImages(imageResize);
      setStoreValue((prevValue) => ({
        ...prevValue,
        storeImages: [
          ...prevValue.storeImages || [],
          ...selectedFiles,
        ],
      }));
    } else if (name === 'state_id') {
      if (changeValue) {
        const city1 = City.getCitiesOfState(countryCode, changeValue.id)
          .map((val) => ({ id: val.isoCode, label: val.name }));
        setCity(city1);
        setStoreValue((prevValue) => ({ ...prevValue, state: changeValue.label, city: '' }));
      }
    } else if (name === 'city_id') {
      if (changeValue) {
        setStoreValue((prevValue) => ({ ...prevValue, city: changeValue.label }));
      }
    } else if (name === 'time_zone') {
      if (changeValue) {
        setStoreValue((prevValue) => ({ ...prevValue, [name]: changeValue.label }));
      }
    } else {
      setStoreValue((prevValue) => ({ ...prevValue, [name]: changeValue }));
    }
  };

  const setLatLng = (data) => {
    setStoreValue((prevValue) => ({ ...prevValue, latitude: data.lat, longitude: data.lng }));
    setNotification(false);
  };

  const addFloorMaps = () => {
    setNotificationStatus(false);
    setNotification(true);
    setModelContent({
      title: 'New Floor',
      content: (
        <AddSingleFloorMap
          closeModal={setNotification}
          submitFloorMap={submitFloorMap}
        />
      ),
    });
  };

  const openMap = () => {
    setNotificationStatus(false);
    setNotification(true);
    setModelContent({
      title: 'Map Location',
      content: (
        <Location
          setLatLng={setLatLng}
          close={setNotification}
          data={{ lat: storeValue.latitude, lng: storeValue.longitude }}
        />
      ),
    });
  };

  useEffect(() => {
    if (timeZone.length === 0) {
      const timeZones = timeZonesList.map((val) => ({ id: val, label: val }));
      setTimeZone(timeZones);
      const country = Country.getAllCountries().map((val) => ({ id: val.isoCode, label: val.name }));
      setCountries(country);
    }
    if (
      storeValue.name
      && storeValue.store_id
      && storeValue.country
      && storeValue.state
      && storeValue.city
      && storeValue.pincode
      && storeValue.area
      && storeValue.time_zone
      && storeValue.longitude
      && storeValue.latitude
    ) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
    dispatch(createNewStoreLocally(storeValue));
  }, [storeValue]);

  return (
    <div className="content content-wrap">
      <ModalBox
        status={notification}
        closeModal={setNotification}
        modalView={modelContent}
        notification={notificationStatus}
      />
      <div className="main-content">
        <TopContent
          label={[t('facilities'), t('newStore')]}
          button={false}
          buttonLabel="Back"
          buttonClass="success-button"
          click={addLocation}
        />
        <div className="content-area">
          <div className="my-content">
            <div className="my-content-area-form-1">
              <div className="location-form">
                <div className="form-header input-text-area">
                  <h2>{t('storeImage')}</h2>
                </div>
                <div className="multi-image-upload">
                  <div className="file-upload-box">
                    <ImageUploadBox
                      title=""
                      setPicture={changeHandle}
                      name="files"
                      height="200px"
                      multiple="multiple"
                      maxFileSize={2}
                    />
                  </div>
                  <div className="images-view">
                    {
                      storeValue?.storeImages?.map((store, index) => (
                        <div className="image-item">
                          <div className="image-close-icon" onClick={() => removeImageFile(index)} role="presentation">
                            <CloseCircleIcon />
                          </div>
                          <div className="image-view" onClick={() => viewImage(index)} role="presentation">
                            <img alt="storage_image" src={store.viewUrl} />
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
                <div className="form-header input-text-area">
                  <h2>{t('storeDetails')}</h2>
                </div>
                <div className="single-row">
                  <Input
                    icon={' '}
                    label={t('storeName')}
                    type="text"
                    name="name"
                    required
                    value={storeValue?.name}
                    change={changeHandle}
                    placeholder={t('enterStoreName')}
                    error={!nameValidation(1, 50, storeValue?.name) && submitButtonClicked}
                    errorMessage={t('storeNameValidation')}
                  />
                  <Input
                    icon={' '}
                    label={t('storeID')}
                    type="text"
                    name="store_id"
                    required
                    value={storeValue?.store_id}
                    change={changeHandle}
                    placeholder={t('enterStoreID')}
                    error={!textNumberValidation(1, 50, storeValue?.store_id) && submitButtonClicked}
                    errorMessage={t('storeIdValidation')}
                  />
                </div>
                <div className="input-text-area">
                  <TextArea
                    icon={' '}
                    label={t('storeDescription')}
                    type="text"
                    name="description"
                    required={false}
                    value={storeValue?.description}
                    change={changeHandle}
                    placeholder={t('enterStoreDescription')}
                    error={!descriptionValidation(500, storeValue?.description) && submitButtonClicked}
                    errorMessage={t('descriptionValidation')}
                  />
                </div>
                <div className="single-row">
                  <SelectBox
                    label={t('timeZone')}
                    name="time_zone"
                    required
                    value={storeValue.time_zone}
                    onchange={changeHandle}
                    lists={timeZone}
                    id="controllable-time-zone-demo"
                    placeholder={t('selectTimeZone')}
                  />
                  <SelectBox
                    label={t('country')}
                    name="country_id"
                    required
                    value={storeValue?.country}
                    onchange={changeHandle}
                    lists={countries}
                    id="controllable-country-demo"
                    placeholder={t('selectCountry')}
                  />
                </div>
                <div className="single-row">
                  <AutocompleteBox
                    label={t('state')}
                    name="state_id"
                    required
                    value={storeValue?.state}
                    onchange={changeHandle}
                    lists={state}
                    id="controllable-state-demo"
                    placeholder={t('selectState')}
                    allowNewOption
                  />
                  <AutocompleteBox
                    label={t('city')}
                    name="city_id"
                    required
                    value={storeValue?.city}
                    onchange={changeHandle}
                    lists={city}
                    id="controllable-city-demo"
                    placeholder={t('selectCity')}
                    allowNewOption
                  />
                </div>
                <div className="single-row">
                  <Input
                    icon={' '}
                    label={t('area')}
                    type="text"
                    name="area"
                    required
                    value={storeValue?.area}
                    change={changeHandle}
                    placeholder={t('enterArea')}
                    error={!descriptionValidation(100, storeValue?.area) && submitButtonClicked}
                    errorMessage={t('areaValidation')}
                  />
                  <Input
                    icon={' '}
                    label={t('pincode')}
                    type="text"
                    name="pincode"
                    required
                    value={storeValue.pincode}
                    change={changeHandle}
                    placeholder={t('enterPincode')}
                    error={!pincodeValidation(storeValue?.pincode) && submitButtonClicked}
                    errorMessage={t('invalidPincode')}
                  />
                </div>
                <div className="single-row">
                  <InputRightInnerIcon
                    icon={locationIcon}
                    label={t('latitude')}
                    type="number"
                    name="latitude"
                    required
                    value={storeValue?.latitude}
                    change={changeHandle}
                    click={openMap}
                    placeholder={t('enterLatitude')}
                    error={!decimalValidation(-90, 90, storeValue?.latitude) && submitButtonClicked}
                    errorMessage={t('invalidLatitude')}
                  />
                  <Input
                    icon={' '}
                    label={t('longitude')}
                    type="number"
                    name="longitude"
                    required
                    value={storeValue?.longitude}
                    change={changeHandle}
                    placeholder={t('enterLongitude')}
                    error={!decimalValidation(-180, 180, storeValue?.longitude) && submitButtonClicked}
                    errorMessage={t('invalidLongitude')}
                  />
                </div>
                <div className="single-row">
                  <Input
                    icon={' '}
                    label={t('zone')}
                    type="text"
                    name="zone"
                    required={false}
                    value={storeValue?.zone}
                    change={changeHandle}
                    placeholder={t('enterZone')}
                    error={!descriptionValidation(50, storeValue?.zone) && submitButtonClicked}
                    errorMessage={t('zoneValidation')}
                  />
                </div>
              </div>
              <div className="location-form">
                <div className="form-header">
                  <h2>{t('floorMap')}</h2>
                  {(storeValue?.floorMapFiles?.length < 20
                    || !Object.prototype.hasOwnProperty.call(storeValue, 'floorMapFiles'))
                    && (
                      <div className="add-button" onClick={addFloorMaps} role="presentation">
                        {t('+addFloor')}
                      </div>
                    )}
                </div>
                <div className="floor-map-list">
                  {
                    storeValue?.floorMapFiles?.map((floorMap, index) => (
                      <div className="floor-map-item">
                        <div className="top-session">
                          <div className="thumb-image">
                            <img src={floorMap.viewImage} alt="floorImages" />
                          </div>
                          <div>
                            <DropDownMenu
                              action={actions}
                              value={{ id: index }}
                              icon={<MoreMenuIcon />}
                              dropdown="tableForm"
                              tooltip=""
                            />
                          </div>
                        </div>
                        <div className="middle-session">
                          {floorMap.name}.{floorMap.type}
                        </div>
                        <div className="footer-session">
                          {floorMap.size}
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-button-group">
          <div className="form-button">
            <Button label={t('back')} click={addLocation} classes="default-button" />
          </div>
          <div className="form-button">
            <Button
              label={uploading ? t('creating') : t('create')}
              click={submit}
              classes={uploading ? 'success-button loading-btn' : 'success-button'}
              disabled={disableButton}
              loading={uploading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LocationAdd;

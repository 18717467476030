/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import playlist from '../../utils/api/playlist';
import { convertBytes, changeDateTimeFormat } from '../../utils/helpers';

export const fetchSchedulePlaylistList = createAsyncThunk(
  'playlist/fetchSchedulePlaylist',
  async (data) => {
    const response = await playlist.getPlaylists(data).then((response1) => {
      const tableValues = response1?.data?.results.map((val) => ({
        ...val,
        author: val.createdBy?.name,
        contents_list: val.contents.map((val1) => val1.name),
        contents: val.contents.map((val1) => ({ ...val1, id: val1._id })),
        total_file_size: val.totalFileSize === 0 ? 'NA' : convertBytes(val.totalFileSize || '0 KB'),
        createDate: changeDateTimeFormat(val.createdAt),
        updateDate: changeDateTimeFormat(val.updatedAt),
        id: val._id,
      }));
      return { ...response1?.data, results: tableValues };
    }).catch((error) => error?.response?.data);
    return response;
  },
);

const initialState = {
  playlist: [],
  pageCount: 0,
  totalPageCount: 1,
  totalDataCount: 0,
  status: 'succeeded',
  filter: {},
  muiTableData: {},
};

export const schedulePlaylistSlice = createSlice({
  name: 'schedulePlaylist',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setMuiTableData: (state, action) => {
      state.muiTableData = action.payload;
    },
    resetSchedulePlaylistState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchSchedulePlaylistList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSchedulePlaylistList.fulfilled, (state, action) => {
        // Add user to the state array
        state.status = 'succeeded';
        if (action?.payload?.code) {
          state.error = action?.payload;
        } else {
          const datas = action.payload.results;
          // if (datas.length > 0) {
          state.pageCount = action.payload.page;
          state.totalPageCount = action.payload.totalPages === 0 ? 1 : action.payload.totalPages;
          state.totalDataCount = action.payload.totalResults;
          if (action.payload.page === 1) {
            state.playlist = datas;
          } else {
            state.playlist.push(...datas);
          }
          // }
        }
      })
      .addCase(fetchSchedulePlaylistList.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {
  addPlaylist,
  setFilter,
  setMuiTableData,
  resetSchedulePlaylistState,
} = schedulePlaylistSlice.actions;

export default schedulePlaylistSlice.reducer;
